import { Grid } from '@mui/material';
import { PDFFooter } from '../PDFFooter/PDFFooter';
import { PDFHeader } from '../PDFHeader/PDFHeader';
import { ProjectedWealthPathChart, ProjectedWealthPathChartProps } from '.';
import { PDFTitle, PDFTitleProps } from '../../organisms/PDF/PDFTitle';
import { useTranslation } from 'react-i18next';

export type PDFProjectedWealthPathChartProps = ProjectedWealthPathChartProps &
    PDFTitleProps & {
        totalPages?: number;
        currentPages?: number;
        retirementYear?: number;
    };
export const PDFProjectedWealthPathChart = ({
    totalPages,
    currentPages,
    bankruptcyYear,
    data,
    plotLine,
    lowPercentile,
    highPercentile,
    targetPercentile,
    goalPriority,
    goalName,
    goalType,
    priority,
    type,
    retirementYear
}: PDFProjectedWealthPathChartProps) => {
    const { t } = useTranslation();
    return (
        <Grid>
            <PDFHeader pageName={t('PROJECTED_WEALTH_PATH_TITLE')} />
            <PDFTitle goalName={goalName} goalType={goalType} priority={priority} type={type} />
            <ProjectedWealthPathChart
                isReadOnly
                data={data}
                bankruptcyYear={bankruptcyYear}
                plotLine={plotLine}
                lowPercentile={lowPercentile}
                highPercentile={highPercentile}
                targetPercentile={targetPercentile}
                goalPriority={goalPriority}
                retirementYear={retirementYear}
            />
            <PDFFooter totalPages={totalPages ?? 1} currentPage={currentPages ?? 1} />
        </Grid>
    );
};
