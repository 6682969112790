import React, { useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useAppDispatch } from '../../../../common/hooks';
import { updateGlobalLoaderState } from '../../../../features/global/globalSlice';

const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1440px',
    maxHeight: '90%',
    overflow: 'scroll',
    backgroundColor: 'common.white',
    boxShadow: 24,
    color: 'common.black',
    opacity: '0'
};

const PDFWrapper = styled('div')(() => ({
    margin: 0,
    minWidth: '1440px'
}));

const InnerWrapper = styled('div')(() => ({
    padding: '15px 25px 5px 25px'
}));

interface PDFViewerProps {
    open?: boolean;
    handleClose?: () => void;
    children?: React.ReactNode;
    filename?: string;
}

const PDFViewer = ({ open, handleClose, children, filename }: PDFViewerProps) => {
    const dispatch = useAppDispatch();
    const generatePDF = useCallback(async () => {
        try {
            dispatch(updateGlobalLoaderState(true));
            const input = document.querySelectorAll('.all-pdf') as NodeListOf<HTMLElement>;
            const len = input.length;

            const pageWidth = 291;
            const pageHeight = 210;
            const frontPageOverflowWidth = 11;

            const doc = new jsPDF({
                orientation: 'l',
                unit: 'mm',
                format: 'a4'
            });

            doc.setDocumentProperties({
                title: filename
            });

            for (let i = 0; i < len; i += 1) {
                const element = input[i];

                const canvas = await html2canvas(element, {
                    logging: true,
                    scale: 2,
                    useCORS: true,
                    allowTaint: true,
                    width: element.offsetWidth,
                    height: element.offsetHeight,
                    windowWidth: element.offsetWidth,
                    windowHeight: element.offsetHeight
                });

                const imgWidth = pageWidth;
                const imgHeight = (canvas.height * pageWidth) / canvas.width;

                let heightLeft = imgHeight;
                let position = 0;
                // for front page
                doc.addImage(
                    canvas.toDataURL('image/jpeg', 1.0),
                    'JPEG',
                    0,
                    position,
                    pageWidth + frontPageOverflowWidth,
                    imgHeight,
                    '',
                    'FAST'
                );

                heightLeft -= pageHeight;
                position = 1;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    // for all other pages
                    doc.addImage(
                        canvas.toDataURL('image/jpeg', 1.0),
                        'JPEG',
                        3,
                        position,
                        imgWidth,
                        imgHeight,
                        '',
                        'FAST'
                    );
                    heightLeft -= pageHeight;
                }

                if (i + 1 < len) {
                    doc.addPage();
                }
            }

            window.open(doc.output('bloburl'), '_blank');
            handleClose && handleClose();
            dispatch(updateGlobalLoaderState(false));
        } catch (error) {
            dispatch(updateGlobalLoaderState(false));
            console.error('PDF generation error:', error);
        }
    }, []);

    useEffect(() => {
        if (open) {
            dispatch(updateGlobalLoaderState(true));
            // explicit wait for Portfolio Composition data dependent
            // on Runpipe and goal calculator api calls and subsequent
            // data modeling for highcharts (Doughnutchart component)
            const timerId = setTimeout(() => {
                generatePDF().catch(console.error);
            }, 500);
            // clear time out when component off loads DOM
            return () => {
                clearTimeout(timerId);
            };
        }
    }, [open, generatePDF]);

    if (!open) return null;

    return (
        <div>
            <Box sx={boxStyle}>
                <PDFWrapper>
                    <InnerWrapper
                        className="all-pdf"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >
                        {children}
                    </InnerWrapper>
                </PDFWrapper>
            </Box>
        </div>
    );
};

export default PDFViewer;
