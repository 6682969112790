import dayjs from 'dayjs';
import { useAppSelector } from '../../../../common/hooks';
import { PDFImportantInformation } from '../../../../components/molecules';
import ReviewPlanDetails from '../../../../containers/review-plan-details';
import PDFLandingPage from '../PDFLanding/PDFLanding';

const GeneratedPlanPDF = () => {
    const { user } = useAppSelector((state) => state?.auth);
    const { globalConfig } = useAppSelector((state) => state?.global);
    const adviceGoals = useAppSelector((state) => state?.adviceGoals);
    const adviceClient = useAppSelector((state) => state?.adviceClient);
    const { keyValueRequest } = useAppSelector((state) => state?.advisorConsole);
    const { _id: selectedClientID } = adviceClient || {};

    const planCreationDate = dayjs(keyValueRequest[selectedClientID]?.__meta?.timestamp).isValid()
        ? dayjs(keyValueRequest[selectedClientID]?.__meta?.timestamp).format('MM/DD/YYYY')
        : '-';

    // supply planUpdationDate here, in AEX-1507
    const planUpdationDate = planCreationDate;

    const totalPagesForPDF =
        adviceGoals?.response?.filter((g) => g?.response?.body?.analysisReport?.isGoalRealistic)?.length * 4 + 1;
    return (
        <>
            <PDFLandingPage
                globalConfig={globalConfig}
                proposalCreatedBy={user?.loggedInUsername}
                proposalCreatedFor={`${adviceClient?.clientFirstName} ${adviceClient?.clientLastName}`}
                proposalPlanCreationDate={planCreationDate}
                proposalPlanUpdationDate={planUpdationDate}
            />
            <ReviewPlanDetails isPDF />
            <PDFImportantInformation
                advisorEmail={user?.userEmail}
                advisorName={user?.loggedInUsername}
                totalPages={totalPagesForPDF}
            />
        </>
    );
};

export default GeneratedPlanPDF;
