import { Grid } from '@mui/material';
import { PDFFooter } from '../../molecules/PDFFooter/PDFFooter';
import { PDFHeader } from '../../molecules/PDFHeader/PDFHeader';
import { PDFTitle } from '../../organisms/PDF/PDFTitle';
import { GoalTypeEnum } from 'common/types';
import { useTranslation } from 'react-i18next';
import { AllocationsOverTimeTab } from '../../../containers/review-plan-details/AllocationsOverTimeTab';

export interface PDFAllocationsOverTimeChartProps {
    goalType: string;
    goalName: string;
    priority: 'Need' | 'Want' | 'Wish' | 'Dream' | 'onTrack' | 'offTrack' | 'atRisk';
    type: GoalTypeEnum;
    retirementYear?: number;
    portfolioPath: Array<number>;
    totalPages: number;
    currentPage: number;
}

export const PDFAllocationsOverTimeChart = ({
    goalType,
    goalName,
    priority,
    type,
    retirementYear,
    portfolioPath,
    totalPages,
    currentPage
}: PDFAllocationsOverTimeChartProps) => {
    const { t } = useTranslation();

    return (
        <Grid container flexDirection="column" height="100%">
            <PDFHeader pageName={t('ALLOCATIONS_OVER_TIME')} />
            <PDFTitle goalType={goalType} goalName={goalName} type={type} priority={priority} />
            <AllocationsOverTimeTab retirementYear={retirementYear} portfolioPath={portfolioPath} isPDF />
            <PDFFooter currentPage={currentPage} totalPages={totalPages} />
        </Grid>
    );
};
