import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet, useNavigate, Navigate } from 'react-router-dom';

import { Spinner } from '../components/atoms';
import { ClickWrapAgreement } from '../components/molecules/ClickWrapAgreement/ClickWrapAgreement';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { logEventToBackEnd, saveUserAgreementsSettings, updateGlobalLoaderState } from '../features/global/globalSlice';
import { logout } from '../features/auth/authSlice';
import dayjs from 'dayjs';

const allowedPathsForAdvisor = ['/clients', '/createclient', '/creategoals', '/reviewplan'];

export const RequiredAuth: React.FC = () => {
    const okta = useOktaAuth();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isAuthenticated = okta?.authState?.isAuthenticated;
    const user = useAppSelector((state) => state.auth.user);
    const global = useAppSelector((state) => state.global);

    const handleLogout = async () => {
        updateGlobalLoaderState(true);
        try {
            const sessionExists = await okta.oktaAuth.session.exists();
            if (sessionExists) {
                await okta.oktaAuth.closeSession();
            }
        } finally {
            const isUserAuthenticated = okta.authState?.isAuthenticated;
            if (isUserAuthenticated) {
                await dispatch(logEventToBackEnd('LOGOUT_SUCCESS'));
                await okta.oktaAuth.tokenManager.clear();
                await okta.oktaAuth.clearStorage();
                dispatch(logout());
            }
        }
    };

    const handleAgreementComplete = async () => {
        await dispatch(
            saveUserAgreementsSettings({
                click_wrap_agreements: {
                    accepted: true,
                    userAggrementAcceptedTimeStamp: dayjs().format('YYYYMMDDHHmmss'),
                    agreements: [
                        {
                            agreement_name: 'UserAgreement',
                            acceptedUserAgreementVersion: user?.userAgreement?.version
                        }
                    ]
                }
            })
        );
    };

    useEffect(() => {
        if (okta?.authState && !isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.origin, window.location.href);
            okta?.oktaAuth.setOriginalUri(originalUri);
            navigate(originalUri);
        }
    }, [okta?.authState, isAuthenticated]);

    if (!isAuthenticated) {
        return <Spinner enabled />;
    }

    if (global.showAgreementModal) {
        return (
            <ClickWrapAgreement
                title="User Terms"
                open={global.showAgreementModal}
                agreements={[
                    {
                        title: 'Terms of Use Agreement',
                        content: user?.userAgreement?.text
                    }
                ]}
                onClose={handleLogout}
                onComplete={() => handleAgreementComplete()}
            />
        );
    }

    if (
        user.userRole !== 'admin' &&
        user.userRole !== 'ftadmin' &&
        !allowedPathsForAdvisor.includes(window.location.pathname)
    ) {
        return <Navigate to="/clients" />;
    }

    return <Outlet />;
};
