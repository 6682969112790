import { Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CLIENT_PROFILE_STAGES, iwsGoalTypeMapper } from '../../common/constants';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { IwsGoalResponseType } from '../../common/types';
import { BasicButton, Typography } from '../../components/atoms';
import { Stepper, StepperProps } from '../../components/molecules';
import { LeftPanel } from '../../components/molecules/LeftPanel/LeftPanel';
import { updateClient, updateCurrentStep } from '../../features/client';
import { removeLoader, showLoader, updateBanner, updateGoal } from '../../features/client-goals';
import { updateGlobalLoaderState } from '../../features/global/globalSlice';
import { iws } from '../../services/clients/iws-service';
import { runpipe } from '../../services/clients/runpipe';
import { CreateClientStep } from './CreateClientStep';
import { CreateGoalsStep } from './CreateGoalsStep';
import { ReviewPlanDetailsStep } from './ReviewPlanDetailsStep';
import { cloneDeep } from 'lodash';
import { goalcalculator } from '../../services/clients/goalcalculator';
import {
    calculateAnnualContribution,
    calculateTotalWealthAvailable,
    calculateTotalIncome
} from '../../containers/funding-source-list/FundingSource';
import AddIncomeSourcesDrawer from '../../containers/add-income-sources-drawer';
import { useState } from 'react';
import { Banner } from '../../components/molecules/Banner/Banner';
import { RiskProfileDrawer } from '../../containers/risk-tolerance-drawer';
import { AddInvestmentAccounts } from '../../containers/add-investment-accounts';
import { useNavigate } from 'react-router-dom';
import PDFViewer from '../../components/organisms/PDF/PDFViewer';
import GeneratedPlanPDF from '../../components/organisms/PDF/GeneratedPDFs/GeneratedPlanPDF';
import { isStep1Completed } from '../../common/helper';
import dayjs from 'dayjs';

interface ClientProfileProps {
    // eslint-disable-next-line no-unused-vars
    updateStep?: (step: number | ((prev: number) => number)) => void;
    disabled?: boolean;
}

const ClientJourney = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const adviceClient = useAppSelector((state) => state.adviceClient);
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    const global = useAppSelector((state) => state.global);
    const [isAddIncomeSourceDrawerOpen, setIsAddIncomeSourceDrawerOpen] = useState(false);
    const [isAddInvestmentSourceDrawerOpen, setIsAddInvestmentSourceDrawerOpen] = useState(false);
    const [isAddRiskProfileDrawerOpen, setIsAddRiskProfileDrawerOpen] = useState(false);
    const [openPDFModal, setOpenPDFModal] = useState(false);
    const navigate = useNavigate();

    const banner = adviceGoals?.global?.banner;
    const isPlanRun = adviceGoals?.global?.isPlanRun;
    const bannerChangesExist = banner?.visible && banner.type === 'attention';
    const shouldRunPipe = adviceGoals.response?.length === 0 || bannerChangesExist;
    const {
        clientFirstName,
        clientLastName,
        relationship,
        riskProfile,
        accounts: { incomeSources, investmentsSources }
    } = adviceClient;
    const clientFullName = `${clientFirstName} ${clientLastName}`;
    const clientRelationship = `${relationship[0]?.firstName} ${relationship[0]?.lastName} (${relationship[0]?.relationship})`;
    const totalAnnualIncome = calculateTotalIncome(incomeSources);
    const totalWealthAvailable = calculateTotalWealthAvailable(investmentsSources);
    const totalAnnualContributions = calculateAnnualContribution(investmentsSources);

    let data = {
        name: clientFullName,
        relationship: relationship.length > 0 ? clientRelationship : null,
        riskProfile: riskProfile,
        totalIncomeAvailable: totalAnnualIncome,
        totalRetirementAvailable: 253330000,
        annualRetirementContributions: 50000,
        totalWealthAvailable: totalWealthAvailable,
        annualWealthContributions: totalAnnualContributions,
        goalAmount: 20240
    };

    const HEADERS = {
        clientemail: global?.orgInfo?.orgEmail,
        version: 4
    };
    //required for future purpose - toast

    // const errorHandler = (err: any) => {
    //     dispatch(updateErrorModalState({ showErrorModal: true, error: { message: err?.response?.data?.message || err?.message, heading: 'Runpipe Error' }}));
    // }

    const handleEditIncomeClick = () => {
        setIsAddIncomeSourceDrawerOpen(true);
    };

    const handleEditInvestmentClick = () => {
        setIsAddInvestmentSourceDrawerOpen(true);
    };

    const onEditRiskProfile = () => {
        setIsAddRiskProfileDrawerOpen(true);
    };

    const handleEditSuccess = async (deletedAccounts: string[] = [], formFields?: any[]) => {
        const formFieldsData = formFields || adviceGoals.formFields;
        if (formFieldsData.length > 0) {
            dispatch(showLoader());
            const newFormFields = formFieldsData.map((goal: any) => {
                return {
                    ...goal,
                    data: {
                        ...goal.data,
                        sources: {
                            ...goal.data.sources,
                            incomeSource: goal.data.sources.incomeSource.filter(
                                (source: any) => !deletedAccounts.includes(source.accountNumber)
                            ),
                            investmentSource: goal.data.sources.investmentSource.filter(
                                (source: any) => !deletedAccounts.includes(source.accountNumber)
                            )
                        }
                    }
                };
            });
            await dispatch(
                updateGoal({
                    ...{ ...adviceGoals, formFields: newFormFields },
                    global: {
                        editMode: true,
                        ...adviceGoals?.global,
                        banner: {
                            visible: true,
                            type: 'attention',
                            isGoalSummary: adviceClient.step === 2 ? false : true
                        }
                    }
                })
            );
            dispatch(removeLoader());
        }
    };

    const handleRiskProfileEditSuccess = async () => {
        dispatch(showLoader());
        await dispatch(
            updateGoal({
                ...adviceGoals,
                global: {
                    ...adviceGoals?.global,
                    status: adviceGoals?.global?.hasRunPipe ? 'editing' : 'draft',
                    banner: {
                        visible: true,
                        type: 'attention',
                        isGoalSummary: adviceClient.step === 1,
                        message:
                            adviceClient.step === 2
                                ? 'You need to review the funding sources for goals.'
                                : t('BANNER_ATTENTION_MESSAGE_SUMMARY')
                    }
                }
            })
        );
        dispatch(removeLoader());
    };

    const calculateProbabilityOfSuccessForProposal = () => {
        const clonedAdviceGoals = cloneDeep(adviceGoals);
        let probabilityOfSuccessArray: number[] = [];
        const modifiedAdviceGoalsFormFields = clonedAdviceGoals.formFields.map((item: any) => {
            const goalJson = clonedAdviceGoals?.response?.find(
                (itemResponse: any) => item.goalId == itemResponse?.goalId
            );
            const analysisReport = goalJson?.['response']?.['body']?.['analysisReport'];

            const portfolio = global.portfolio?.filter(
                (_sPort) => _sPort.portfolioId === analysisReport?.['recommendedPortfolioId']
            );

            const groupedPortfolio = portfolio?.[0]?.assets?.map((_type: any) => ({
                type: _type.type,
                totalAllocatedvalue: `${_type.totalAllocatedvalue}%`
            }));
            // get equity fixed income
            const equityFixedIncomeCash = groupedPortfolio?.reduce(
                (
                    acc: { equity: number; fixedIncome: number; cash: number },
                    item: { type: string; totalAllocatedvalue: string }
                ) => {
                    if (item.type === 'Equity') {
                        acc.equity += parseFloat(item.totalAllocatedvalue);
                    } else if (item.type === 'Fixed Income') {
                        acc.fixedIncome += parseFloat(item.totalAllocatedvalue);
                    } else if (item.type === 'Cash') {
                        acc.cash += parseFloat(item.totalAllocatedvalue);
                    }
                    return acc;
                },
                { equity: 0, fixedIncome: 0, cash: 0 }
            );
            const equityFixedIncomeString = `${Math.round(equityFixedIncomeCash?.equity)} / ${Math.round(
                equityFixedIncomeCash?.fixedIncome
            )} / ${Math.round(equityFixedIncomeCash?.cash)}`;

            // get probability of success
            const probabilityOfSuccess = Number(analysisReport?.['currentGoalProbability'] * 100);
            item.data.probabilityOfSuccess = probabilityOfSuccess;
            item.data.equityFixedIncome = equityFixedIncomeString;
            probabilityOfSuccessArray.push(probabilityOfSuccess);
            return item;
        });
        return { modifiedAdviceGoalsFormFields, probabilityOfSuccessArray };
    };

    const handleSavePlan = async () => {
        const { modifiedAdviceGoalsFormFields, probabilityOfSuccessArray } = calculateProbabilityOfSuccessForProposal();
        dispatch(showLoader());
        await dispatch(
            updateGoal({
                ...adviceGoals,
                formFields: modifiedAdviceGoalsFormFields,
                global: {
                    ...adviceGoals?.global,
                    status: 'draft',
                    probabilityOfSuccess: Math.min(...probabilityOfSuccessArray)
                }
            })
        );
        dispatch(removeLoader());
        navigate('/clients');
    };

    const generateAccountsArray = (
        goalId: string,
        incomeSource: any,
        incomeSources: any,
        investmentSource: any,
        investmentsSources: any
    ) => {
        let accountsArray = [
            ...incomeSource?.flatMap((account: any) => {
                const accountDetails: any = incomeSources?.find((ac: any) => ac.accountId === account.accountNumber);
                if (!accountDetails) {
                    return []; // might have been deleted
                }

                return {
                    fundingType: 'incomeSources',
                    recurringContribution: Number(accountDetails?.amount) || 0,
                    escalationPercentage: Number(accountDetails?.escalateIncome) || 0,
                    escalationYears: 1,
                    frequency: accountDetails?.frequency === 'Monthly' ? 'monthly' : 'yearly',
                    startDate: accountDetails?.beginningYear, // since only beginningYear is captured from UI
                    endDate: accountDetails?.endYear
                        ? accountDetails?.endYear
                        : Number(accountDetails?.beginningYear) + 5 // if end year is not given by user, using beginningYear + 5
                };
            })
        ];
        accountsArray = [
            ...accountsArray,
            ...investmentSource?.map((account: any) => {
                const accountDetails: any = investmentsSources?.find(
                    (ac: any) => ac.accountId === account.accountNumber
                );
                return {
                    fundingType: 'investmentAccounts',
                    amount: accountDetails?.amount,
                    recurringContribution: Number(accountDetails?.recurringContributions) || 0,
                    escalationPercentage: Number(accountDetails?.escalationContributions) || 0,
                    escalationYears: 1,
                    startDate: accountDetails?.recurringContributionsStartDate,
                    endDate: accountDetails?.recurringContributionsEndDate,
                    frequency: accountDetails?.frequency === 'Monthly' ? 'monthly' : 'yearly',
                    ...(account?.isRCSelected && { includeRecurringContributionsForGoal: goalId })
                };
            })
        ];
        return accountsArray;
    };
    const prepareIWSData = () => {
        //payload for wealth goals
        return {
            riskProfile: adviceClient?.riskProfile,
            goals: adviceGoals?.formFields?.map((goal: any) => {
                return {
                    goalId: goal?.goalId,
                    goalType: iwsGoalTypeMapper[goal?.data?.goalType],
                    goalName: goal?.data?.goalName || '',
                    riskProfile: adviceClient?.riskProfile,
                    targetDate: goal?.data?.goalDate || goal?.data?.targetDate,
                    goalAmount: goal?.data?.goalAmount,
                    targetedRetirementIncome: goal?.data?.targetedRetirementIncome,
                    planStartRetirement: goal?.data?.planStartRetirement,
                    goalPriority: goal?.data?.goalPriority,
                    accounts: generateAccountsArray(
                        goal?.goalId,
                        goal?.data?.sources?.incomeSource,
                        adviceClient?.accounts?.incomeSources,
                        goal?.data?.sources?.investmentSource,
                        adviceClient?.accounts?.investmentsSources
                    )
                };
            })
        };
    };

    const prepareRunpipeData = (goal: any, goalResponse: IwsGoalResponseType) => {
        let runpipeData = {
            goalId: goal.goalId,
            goalType: iwsGoalTypeMapper[goal?.data?.goalType],
            goalName: goal?.data?.goalName || '',
            riskProfile: adviceClient?.riskProfile,
            targetDate: goal?.data?.goalDate || goal?.data?.targetDate,
            ...(goal?.data?.goalAmount !== undefined && { goalAmount: goal.data.goalAmount }),
            goalPriority: goal?.data?.goalPriority,
            ...(goal?.data?.planStartRetirement && {
                planStartRetirement: goal.data.planStartRetirement
            }),
            ...(goal?.data?.targetedRetirementIncome !== undefined && {
                targetedRetirementIncome: goal.data.targetedRetirementIncome
            }),
            accounts: generateAccountsArray(
                goal?.goalId,
                goal?.data?.sources?.incomeSource,
                adviceClient?.accounts?.incomeSources,
                goal?.data?.sources?.investmentSource,
                adviceClient?.accounts?.investmentsSources
            )
        };
        if (goalResponse)
            return {
                ...runpipeData,
                recommendedWealthSplit: goalResponse?.wealthSplit,
                isIWSApplied: true
            };
        return { ...runpipeData, isIWSApplied: false };
    };

    const fetchIWSandRunpipe = async (onlyRunpipe = false) => {
        // IWS api call
        const iwsResponse = !onlyRunpipe ? await iws(prepareIWSData(), HEADERS) : { goalResponseList: [] };
        let requestArrayForRedux: any = [];
        let requestArrayForReduxGoalAmt: any = [];
        let responseArrayForRedux: any = [];
        let responseArrayForReduxGoalAmt: any = [];
        let keyData: any = [];
        let newArr: any = [];
        let newArrGoalAmt: any = [];
        let recommendationArr: any = [];
        // parallel runpipe api calls for each goal
        adviceGoals?.formFields?.map(async (goal: any) => {
            const runpipeRequest = prepareRunpipeData(
                goal,
                iwsResponse?.goalResponseList?.find((g: IwsGoalResponseType) => g.goalId === goal.goalId)
            );
            const runpipeRequestGoalAmt = cloneDeep(runpipeRequest);
            runpipeRequestGoalAmt['goalAmount'] = 0;

            requestArrayForRedux.push(runpipeRequest);
            requestArrayForReduxGoalAmt.push(runpipeRequestGoalAmt);

            const newPrRunpipe = Promise.all([runpipe(runpipeRequest, HEADERS)]);
            // const newPrRunpipe = Promise.all([runpipe(runpipeRequest, HEADERS,errorHandler)]);
            responseArrayForRedux.push(newPrRunpipe);
            // keyData.push(goal.goalId);
            const newPrGoalAmt = Promise.all([goalcalculator(runpipeRequestGoalAmt, HEADERS)]);
            responseArrayForReduxGoalAmt.push(newPrGoalAmt);
            keyData.push(goal.goalId);
            recommendationArr.push({
                goalId: runpipeRequest.goalId,
                orginalAmount: runpipeRequest.goalAmount,
                oneTimeTopUp: 0,
                topUpAccumulation: 0,
                toUpDeccumulation: 0,
                recomendedTenure: 0
            });
        });

        const resPr = await Promise.all(responseArrayForRedux);
        // eslint-disable-next-line no-unused-vars
        resPr.forEach((reponseData, index) => {
            if (resPr[index][0]) {
                resPr[index][0]['goalId'] = keyData[index];
                newArr.push(resPr[index][0]);
            }
        });
        const resPrGoalAmt = await Promise.all(responseArrayForReduxGoalAmt);
        // eslint-disable-next-line no-unused-vars
        resPrGoalAmt.forEach((reponseData, index) => {
            if (resPrGoalAmt[index][0]) {
                resPrGoalAmt[index][0]['goalId'] = keyData[index];
                newArrGoalAmt.push(resPrGoalAmt[index][0]);
            }
        });

        await dispatch(
            updateGoal({
                ...adviceGoals,
                request: requestArrayForRedux,
                response: newArr,
                recommendation: recommendationArr,
                global: {
                    ...adviceGoals?.global,
                    hasRunPipe: true,
                    ...(adviceGoals?.global?.banner?.visible && {
                        banner: { ...adviceGoals?.global?.banner, type: 'success' }
                    }),
                    responseGoalCalculator: newArrGoalAmt,
                    isPlanRun: true
                }
            })
        );
    };

    const prepareForReviewPlanStep = async () => {
        // only runpipe if goals added = 1 OR wealthSources added = 0
        if (adviceGoals?.formFields?.length === 1 || adviceClient?.accounts?.investmentsSources?.length === 0) {
            await fetchIWSandRunpipe(true); // onlyRunpipe = true
        } else if (adviceGoals?.formFields?.length > 1) {
            // IWS and Runpipe calls for multiple goals
            await fetchIWSandRunpipe();
        }
    };

    //styled elements
    const JourneyContainer = styled('div')(() => ({
        display: 'flex',
        flexDirection: 'row',
        padding: '0 40px'
        // marginBottom: '80px'
        // maxWidth: theme.breakpoints.values.lg // let's not add a maxWidth until we have a solution for the LeftPanel position
    }));

    const ContinueToNextStep = ({ updateStep, disabled }: ClientProfileProps) => (
        <BasicButton
            variant="contained"
            onClick={async () => {
                updateStep?.((prev) => prev + 1);
                // update the step in redux
                await dispatch(updateCurrentStep(adviceClient.step + 1));
                await dispatch(updateClient({ ...adviceClient, step: adviceClient.step + 1 }));
            }}
            disabled={disabled}
        >
            {t('CLIENT_ONBOARDING_CONTD_BTN')}
        </BasicButton>
    );

    const ContinueToReviewStep = ({ updateStep, disabled }: ClientProfileProps) => (
        <BasicButton
            variant="contained"
            onClick={async () => {
                if (shouldRunPipe) {
                    dispatch(updateGlobalLoaderState(true));
                    await prepareForReviewPlanStep();
                    dispatch(updateGlobalLoaderState(false));
                }
                updateStep?.((prev) => prev + 1);
                // update the step in redux
                await dispatch(updateCurrentStep(adviceClient.step + 1));
            }}
            disabled={disabled}
        >
            {t('CLIENT_ONBOARDING_CONTD_BTN')}
        </BasicButton>
    );

    // Initializing Stepper steps
    const CreateProfile = ({ updateStep }: ClientProfileProps) => (
        <>
            <Grid container justifyContent="space-between">
                <Typography variant="headers.h2" color="neutral.grey.500" t="CLIENT_ONBOARDING_STEP1"></Typography>
                <ContinueToNextStep
                    updateStep={updateStep}
                    disabled={!isStep1Completed(CLIENT_PROFILE_STAGES, adviceClient)}
                />
            </Grid>
            {banner && banner.visible && banner.type != 'success' && adviceGoals?.formFields?.length > 0 && (
                <Banner
                    type={banner.type || 'attention'}
                    handleClose={() => {
                        dispatch(updateBanner({ visible: false }));
                    }}
                    handleTryAgain={async () => {
                        updateStep?.(1);
                        await dispatch(updateCurrentStep(1));
                    }}
                    isGoalSummary={false}
                    message={banner.message}
                />
            )}
            <CreateClientStep />
        </>
    );

    const CreateGoals = ({ updateStep }: ClientProfileProps) => (
        <>
            <Grid container justifyContent="space-between" alignItems={'center'}>
                <Typography variant="headers.h2" color="neutral.grey.500" t="CLIENT_ONBOARDING_STEP2"></Typography>

                <ContinueToReviewStep
                    updateStep={updateStep}
                    disabled={!adviceGoals?.formFields || adviceGoals?.formFields?.length < 1}
                />
            </Grid>
            {isPlanRun && banner && banner.visible && banner.type != 'success' && adviceGoals?.formFields?.length > 0 && (
                <Banner
                    type={banner.type || 'attention'}
                    handleClose={() => {
                        dispatch(updateBanner({ visible: false }));
                    }}
                    handleTryAgain={async () => {
                        if (shouldRunPipe) {
                            dispatch(showLoader());
                            await prepareForReviewPlanStep();
                            dispatch(removeLoader());
                        }
                        updateStep?.((prev) => prev + 1);
                        await dispatch(updateCurrentStep(adviceClient.step + 1));
                    }}
                    isGoalSummary={banner.isGoalSummary}
                    message={banner.message}
                />
            )}
            <CreateGoalsStep />
        </>
    );

    const ReviewPlanDetails = ({ updateStep }: ClientProfileProps) => {
        return (
            <>
                <Grid container justifyContent="space-between">
                    <Typography variant="headers.h2" color="neutral.grey.500" t="CLIENT_ONBOARDING_STEP4"></Typography>
                    <Grid>
                        <BasicButton variant="outlined" onClick={() => setOpenPDFModal(true)} loading={openPDFModal}>
                            {t('CLIENT_ONBOARDING_Share_PDF')}
                        </BasicButton>
                        <BasicButton variant="contained" sx={{ marginLeft: '10px' }} onClick={handleSavePlan}>
                            {t('CLIENT_ONBOARDING_STEP5')}
                        </BasicButton>
                    </Grid>
                </Grid>
                {isPlanRun && banner && banner.visible && (
                    <Banner
                        type={banner.type || 'attention'}
                        handleClose={() => {
                            dispatch(updateBanner({ visible: false }));
                        }}
                        message={banner.message}
                        handleTryAgain={
                            banner.isGoalSummary
                                ? async () => {
                                      try {
                                          if (shouldRunPipe) {
                                              dispatch(showLoader());
                                              await prepareForReviewPlanStep();
                                              dispatch(removeLoader());
                                          }
                                      } catch {
                                          dispatch(removeLoader());
                                      }
                                  }
                                : async () => {
                                      updateStep?.(1);
                                      // update the step in redux
                                      await dispatch(updateCurrentStep(1));
                                      dispatch(
                                          updateBanner({
                                              ...banner,
                                              type: 'attention',
                                              isGoalSummary: true,
                                              message: ''
                                          })
                                      );
                                  }
                        }
                        isGoalSummary={banner.isGoalSummary}
                    />
                )}
                <ReviewPlanDetailsStep updateStep={updateStep} />
            </>
        );
    };

    const journeySteps: StepperProps = {
        steps: [
            { name: t('CLIENT_ONBOARDING_STEP1'), content: <CreateProfile /> },
            { name: t('CLIENT_ONBOARDING_STEP2'), content: <CreateGoals /> },
            { name: t('CLIENT_ONBOARDING_STEP4'), content: <ReviewPlanDetails /> }
        ],
        initialStepIndex: adviceClient.step,
        runPlan: prepareForReviewPlanStep
    };

    const generatePDFfilename = () =>
        `${clientFullName?.trim()} ${dayjs(new Date()).format('DD/MM/YYYY').replaceAll('/', '')}`;

    return (
        <>
            {
                <PDFViewer
                    open={openPDFModal}
                    handleClose={() => setOpenPDFModal(false)}
                    filename={generatePDFfilename()}
                >
                    <GeneratedPlanPDF />
                </PDFViewer>
            }
            <JourneyContainer>
                {journeySteps.initialStepIndex === 1 || journeySteps.initialStepIndex === 2 ? (
                    <>
                        <LeftPanel
                            data={data}
                            onEditIncomeClick={handleEditIncomeClick}
                            onEditInvestmentClick={handleEditInvestmentClick}
                            onEditRiskProfile={onEditRiskProfile}
                            expanded={true}
                        />
                        <AddIncomeSourcesDrawer
                            setOpenDrawer={setIsAddIncomeSourceDrawerOpen}
                            openDrawer={isAddIncomeSourceDrawerOpen}
                            mode="edit"
                            onEditSuccess={handleEditSuccess}
                        />
                        {isAddInvestmentSourceDrawerOpen && (
                            <AddInvestmentAccounts
                                setOpenDrawer={setIsAddInvestmentSourceDrawerOpen}
                                openDrawer={isAddInvestmentSourceDrawerOpen}
                                mode="edit"
                                onEditSuccess={handleEditSuccess}
                            />
                        )}
                        <RiskProfileDrawer
                            setOpenDrawer={setIsAddRiskProfileDrawerOpen}
                            openDrawer={isAddRiskProfileDrawerOpen}
                            mode="edit"
                            onEditSuccess={handleRiskProfileEditSuccess}
                        />
                    </>
                ) : null}
                <Stepper {...journeySteps} />
            </JourneyContainer>
        </>
    );
};
export default ClientJourney;
