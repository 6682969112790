import { styled } from '@mui/material';
import dayjs from 'dayjs';
import { cloneDeep, sumBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getYYYYMMDDfromDate } from '../../common/helper';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { Chip, Spinner } from '../../components/atoms';
import { Typography } from '../../components/atoms/Typography/Typography';
import {
    ConfirmationDialog,
    PDFFooter,
    PDFHeader,
    PlanInfoAndReRunSection,
    UniversalCard
} from '../../components/molecules';
import { PDFAllocationsOverTimeChart } from '../../components/molecules/AllocationsOverTimeChart/PDFAllocationsOverTimeChart';
import { GoalType } from '../../components/molecules/GoalType/GoalType';
import {
    PlanSummaryProgressBar,
    PlanSummaryProgressBarValue
} from '../../components/molecules/PlanSummaryProgressBar/PlanSummaryProgressBar';
import { ProbabilityOfSuccess } from '../../components/molecules/ProbabilityOfSuccess/ProbabilityOfSuccess';
import { ProjectedWealthPathChart } from '../../components/molecules/ProjectedWealthPathChart';
import { PDFProjectedWealthPathChart } from '../../components/molecules/ProjectedWealthPathChart/PDFProjectedWealthPathChart';
import { Tabs, TabVariant } from '../../components/molecules/Tabs/Tabs';
import PortfolioCompositionContainer from '../../components/organisms/PortfolioComposition';
import { PDFPortfolioComposition } from '../../components/organisms/PortfolioComposition/PDFPortfolioComposition';
import { RetirementGoals } from '../../containers/retirement-goals-drawer';
import { WealthGoals } from '../../containers/wealth-goals-drawer';
import { updateCurrentStep } from '../../features/client';
import { handleGoalCalculatorResponse, syncAdviceGoals, updateBanner } from '../../features/client-goals';
import { goalcalculator } from '../../services/clients/goalcalculator';
import { runpipe } from '../../services/clients/runpipe';
import { PATPalette } from '../../themes/palette';
import { calculateYearsBetweenDates } from '../../utils/index';
import { AllocationsOverTimeTab } from './AllocationsOverTimeTab';
import { UnrealisticGoal } from './UnrealisticGoal';

export interface RecomendationProps {
    isRecomendation?: boolean;
}

export const StyledDisclaimerContent = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: theme.spacing(1.5),
    margin: `${theme.spacing(3)} 0`,
    lineHeight: theme.spacing(2),
    color: PATPalette.neutral.grey[400]
}));

const ProbSuccessDiv = styled('div')<RecomendationProps>(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    gap: '32px'
}));

const HeaderDiv = styled('div')(({ isPDF }: { isPDF: boolean }) => ({
    display: 'flex',
    alignItems: 'start',
    marginBottom: '20px',
    paddingBottom: isPDF ? '10px' : 'inherit',
    borderBottom: isPDF ? '1px solid #BFBFBF' : 'inherit',
    marginTop: isPDF ? '22px' : '32px'
}));

const StyledChipContainer = styled('div')(() => ({
    marginBottom: '3px',
    marginLeft: '12px'
}));

const GoalSummary = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        goalId,
        goalJson,
        goeConfig,
        goalsData,
        goalcalculatorJson,
        goalName,
        goalType,
        isGoalRealistic,
        isRetirement,
        // goalDataRecomendation,
        updateStep,
        type,
        isPDF
    } = props;
    const global = useAppSelector((state) => state.global);
    const { portfolio: allMarketPortfolios } = useAppSelector((state) => state.global);
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    const goalResp = cloneDeep(goalJson[0]);
    const allRealisticGoals = adviceGoals?.response?.filter((g) => g?.response?.body?.analysisReport?.isGoalRealistic);
    const totalPagesForPDF = allRealisticGoals?.length * 4 + 1; // 4 pages in PDF per goal + 1 for last page
    const HEADERS = {
        clientemail: global?.orgInfo?.orgEmail,
        version: 4
    };

    // will use this later if required
    // const findYears = useCallback(
    //     (probData: any) => {
    //         if (probData != 'NA') {
    //             if (!isRetirement) {
    //                 const splitedData = probData.split(';');
    //                 let years = 0;
    //                 let splitedDataPer = splitedData[0].split(':');
    //                 const splitedDataPerT = splitedDataPer[0].trim().split(' ');
    //                 years = parseInt(splitedDataPerT[3].split('+')[1]);
    //                 return years;
    //             } else {
    //                 const splitedData = probData.split(';');
    //                 let years = 0;
    //                 let splitedDataPer = splitedData[0].split(':');
    //                 if (splitedData.length > 1) {
    //                     splitedDataPer = splitedData[1].split(':');
    //                 }
    //                 const splitedDataPerT = splitedDataPer[0].trim().split(' ');
    //                 splitedDataPerT.forEach((element: any) => {
    //                     if (Number(element)) {
    //                         years = Number(element);
    //                     }
    //                 });
    //                 return years;
    //             }
    //         }
    //         return 0;
    //     },
    //     [goalResp]
    // );

    function calculateAmountAfterMonths(startDateInput: string, endDateInput: string): number {
        // Helper function to parse DD-MM-YYYY formatted date
        const parseDate = (dateInput: string): Date => {
            const [month, day, year] = dateInput.split('-').map(Number);
            return new Date(year, month - 1, day); // months are 0-indexed in JavaScript Date
        };

        const currentDate = new Date();
        const startDate = parseDate(startDateInput);
        const endDate = parseDate(endDateInput);

        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // current month is 0-indexed, so add 1
        const startYear = startDate.getFullYear();
        const startMonth = startDate.getMonth() + 1;
        const endYear = endDate.getFullYear();
        const endMonth = endDate.getMonth() + 1;
        if (currentYear < startYear) {
            return 0; // Current year is before the start year
        }
        if (currentYear > endYear) {
            return 0; // Current year is after the end year
        }
        if (currentYear === startYear) {
            return 12 - (startMonth - 1); // Current year and start year match
        }
        if (currentYear === endYear) {
            if (currentMonth <= endMonth) {
                return endMonth; // Current year and end year match, and current month <= end month
            } else {
                return 0; // Current month is after the end month
            }
        }
        return 12; // Default case when current year is between start year and end year
    }

    const calculateInitialAmount = (accounts = []) => {
        const { incomeSource = [], investmentSource } =
            adviceGoals?.formFields?.find?.((item) => item.goalId === goalId)?.data?.sources || {};
        const sumIncomeSource = sumBy(
            incomeSource,
            ({ isSelected, accountBalance, beginningYear = '01-01-2025', endYear = '01-01-2025', frequency }: any) => {
                if (!isSelected) {
                    return 0;
                }
                let partsbeginningYear = beginningYear.split('-');
                let partsendYear = endYear.split('-');
                // Rearrange the parts to mm-dd-yyyy format
                let formattedDatebeginningYear = `${partsbeginningYear[1]}-${partsbeginningYear[0]}-${partsbeginningYear[2]}`;
                let formattedendYear = `${partsendYear[1]}-${partsendYear[0]}-${partsendYear[2]}`;
                const result = calculateAmountAfterMonths(formattedDatebeginningYear, formattedendYear);
                const updatedAmount = frequency === 'Annually' ? accountBalance : result * (accountBalance / 12);
                return updatedAmount;
            }
        );

        const sumInvestmentSource = sumBy(
            investmentSource,
            ({
                isRCSelected,
                frequency,
                recurringContributions,
                recurringContributionsStartDate,
                recurringContributionsEndDate
            }: any) => {
                if (!isRCSelected) {
                    return 0;
                }
                const result = calculateAmountAfterMonths(
                    recurringContributionsStartDate || '01-01-2025',
                    recurringContributionsEndDate || '01-01-2025'
                );
                const updatedAmount =
                    frequency === 'Annually' ? recurringContributions : result * recurringContributions;
                return updatedAmount;
            }
        );

        const annualContribution = sumIncomeSource + sumInvestmentSource;

        const initialInvestment = sumBy(accounts, 'amount') || 0;
        return { initialInvestment, annualContribution };
    };

    const applyRecommendation = async (selectedRecomendationData: any) => {
        const goalJson = cloneDeep(goalsData);
        if (selectedRecomendationData.key === 'oneTimeTopUp') {
            goalJson.accounts.push({
                fundingType: 'investmentAccounts',
                recommendationType: 'oneTimeTopUp',
                amount: selectedRecomendationData.optionValue,
                recurringContribution: 0,
                escalationPercentage: 0,
                escalationYears: 1,
                frequency: 'yearly',
                startDate: dayjs().format('DD-MM-YYYY'),
                endDate: goalsData.targetDate
            });
            const runpipeRequestData = adviceGoals.request.map((item: any) => {
                return item?.goalId && item.goalId === goalId ? { ...item, accounts: goalJson.accounts } : item;
            });
            const runpipeResponse = await runpipe(goalJson, HEADERS);
            const runpipeResponseData = adviceGoals.response.map((item: any) => {
                return item?.goalId && item.goalId === goalId ? { ...item, ...runpipeResponse } : item;
            });
            const recomendationResp = adviceGoals.recommendation.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, oneTimeTopUp: selectedRecomendationData.optionValue }
                    : item;
            });
            dispatch(
                syncAdviceGoals({
                    ...adviceGoals,
                    request: runpipeRequestData,
                    response: runpipeResponseData,
                    recommendation: recomendationResp
                })
            );
            goalJson['goalAmount'] = 0;
            const GcalculatorResponse = await goalcalculator(goalJson, HEADERS);
            const GcalculatorResponseData = adviceGoals.global?.responseGoalCalculator?.map((item: any) => {
                return item?.goalId && item.goalId === goalId ? { ...item, ...GcalculatorResponse } : item;
            });
            dispatch(handleGoalCalculatorResponse(GcalculatorResponseData));
        } else if (selectedRecomendationData.key === 'topUpAccumulation') {
            goalJson.accounts.push({
                fundingType: 'investmentAccounts',
                amount: 0,
                recommendationType: 'topUpAccumulation',
                recurringContribution: selectedRecomendationData.optionValue,
                escalationPercentage: 0,
                escalationYears: 1,
                frequency: 'yearly',
                startDate: dayjs().format('DD-MM-YYYY'),
                endDate: isRetirement
                    ? getYYYYMMDDfromDate(dayjs(goalsData.planStartRetirement).subtract(1, 'day'))
                    : goalsData.targetDate,
                includeRecurringContributionsForGoal: goalId
            });
            const runpipeResponse = await runpipe(goalJson, HEADERS);
            const runpipeRequestData = adviceGoals.request.map((item: any) => {
                return item?.goalId && item.goalId === goalId ? { ...item, accounts: goalJson.accounts } : item;
            });
            const runpipeResponseData = adviceGoals.response.map((item: any) => {
                return item?.goalId && item.goalId === goalId ? { ...item, ...runpipeResponse } : item;
            });

            const recomendationResp = adviceGoals.recommendation.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, topUpAccumulation: selectedRecomendationData.optionValue }
                    : item;
            });

            dispatch(
                syncAdviceGoals({
                    ...adviceGoals,
                    request: runpipeRequestData,
                    response: runpipeResponseData,
                    recommendation: recomendationResp
                })
            );

            goalJson['goalAmount'] = 0;
            const GcalculatorResponse = await goalcalculator(goalJson, HEADERS);
            const GcalculatorResponseData = adviceGoals.global?.responseGoalCalculator?.map((item: any) => {
                return item?.goalId && item.goalId === goalId ? { ...item, ...GcalculatorResponse } : item;
            });
            dispatch(handleGoalCalculatorResponse(GcalculatorResponseData));
        } else if (selectedRecomendationData.key === 'recommendedTenure') {
            const dateStr = goalJson.targetDate;
            const [day1, month1, year1] = dateStr.split('-').map(Number);
            const date = new Date(year1, month1 - 1, day1);
            const newDate = date.setFullYear(date.getFullYear() + selectedRecomendationData.optionValue);
            var updatedDate = new Date(newDate);
            // Extract day, month, and year
            var day = updatedDate.getDate();
            var month = updatedDate.getMonth() + 1;
            var year = updatedDate.getFullYear();
            const formattedDay: string = day < 10 ? '0' + day : day.toString();
            const formattedMonth: string = month < 10 ? '0' + month : month.toString();
            // Format the date as dd-mm-yyyy
            const formattedDate: string = formattedDay + '-' + formattedMonth + '-' + year;
            if (!isRetirement) goalJson['targetDate'] = formattedDate;
            if (isRetirement) {
                const dateStr = goalsData.planStartRetirement;
                const [day1, month1, year1] = dateStr.split('-').map(Number);
                const date = new Date(year1, month1 - 1, day1);
                const newDate = date.setFullYear(date.getFullYear() + selectedRecomendationData.optionValue);
                updatedDate = new Date(newDate);
                // Extract day, month, and year
                day = updatedDate.getDate();
                month = updatedDate.getMonth() + 1;
                year = updatedDate.getFullYear();
                const formattedDay: string = day < 10 ? '0' + day : day.toString();
                const formattedMonth: string = month < 10 ? '0' + month : month.toString();
                // Format the date as dd-mm-yyyy
                const formattedDate: string = formattedDay + '-' + formattedMonth + '-' + year;
                goalJson['planStartRetirement'] = formattedDate;
            }
            const runpipeResponse = await runpipe(goalJson, HEADERS);
            const runpipeRequestData = adviceGoals.request.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? isRetirement
                        ? { ...item, planStartRetirement: goalJson['planStartRetirement'] }
                        : { ...item, targetDate: formattedDate }
                    : item;
            });
            const runpipeResponseData = adviceGoals.response.map((item: any) => {
                return item?.goalId && item.goalId === goalId ? { ...item, ...runpipeResponse } : item;
            });

            const recomendationResp = adviceGoals.recommendation.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, recomendedTenure: selectedRecomendationData.optionValue }
                    : item;
            });

            let originalGoalsData: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
            originalGoalsData?.formFields.forEach((item) => {
                let itemData = item['data'];
                if (item.goalId === goalsData?.goalId && itemData.goalType === 'retirement') {
                    itemData['planStartRetirement'] = goalJson['planStartRetirement'];
                }
            });
            console.log('originalGoalsData', originalGoalsData);
            dispatch(
                syncAdviceGoals({
                    ...adviceGoals,
                    formFields: originalGoalsData['formFields'],
                    request: runpipeRequestData,
                    response: runpipeResponseData,
                    recommendation: recomendationResp
                })
            );
            goalJson['goalAmount'] = 0;
            const GcalculatorResponse = await goalcalculator(goalJson, HEADERS);
            const GcalculatorResponseData = adviceGoals.global?.responseGoalCalculator?.map((item: any) => {
                return item?.goalId && item.goalId === goalId ? { ...item, ...GcalculatorResponse } : item;
            });
            dispatch(handleGoalCalculatorResponse(GcalculatorResponseData));
        } else if (isRetirement && selectedRecomendationData.key === 'topUpDecumulation') {
            const newIncome = goalJson['targetedRetirementIncome'] - selectedRecomendationData.optionValue;
            goalJson['targetedRetirementIncome'] = newIncome;
            const runpipeResponse = await runpipe(goalJson, HEADERS);
            const runpipeRequestData = adviceGoals.request.map((item: any) => {
                return item?.goalId && item.goalId === goalId ? { ...item, targetedRetirementIncome: newIncome } : item;
            });
            const runpipeResponseData = adviceGoals.response.map((item: any) => {
                return item?.goalId && item.goalId === goalId ? { ...item, ...runpipeResponse } : item;
            });

            const recomendationResp = adviceGoals.recommendation.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, toUpDeccumulation: selectedRecomendationData.optionValue }
                    : item;
            });

            dispatch(
                syncAdviceGoals({
                    ...adviceGoals,
                    request: runpipeRequestData,
                    response: runpipeResponseData,
                    recommendation: recomendationResp
                })
            );

            goalJson['goalAmount'] = 0;
            const GcalculatorResponse = await goalcalculator(goalJson, HEADERS);
            const GcalculatorResponseData = adviceGoals.global?.responseGoalCalculator?.map((item: any) => {
                return item?.goalId && item.goalId === goalId ? { ...item, ...GcalculatorResponse } : item;
            });
            dispatch(handleGoalCalculatorResponse(GcalculatorResponseData));
        }
    };

    const goalAmtRetirement = (arr: any) => {
        let min;
        for (let i of arr) {
            if (i < 0) {
                min = i;
                break;
            }
        }

        return -1 * min;
    };

    const startDate = new Date();
    const dateStr = goalsData.targetDate;
    const [day, month, year] = dateStr.split('-').map(Number);
    const endDate = new Date(year, month - 1, day);
    const infusionArray = goalcalculatorJson?.[0]?.['response']?.['body']?.['infusions'];
    const [tabIndex, setTabIndex] = useState('0');
    const calculatedAmountval = calculateInitialAmount(goalsData.accounts);
    const analysisReport = goalResp['response']['body']['analysisReport'];
    const pathReport = goalResp['response']['body']['pathReport'];
    const goalPriorityValue = goeConfig['pipe_config']['goal_priority_prob_list'];
    const goalUnrealisticValue = goeConfig['pipe_config']['realistic_goal_prob'];
    const currentGoalProbability = ~~Number(analysisReport['currentGoalProbability'] * 100);
    const goalPriorityProbability =
        goalPriorityValue[goalsData['goalPriority'] as 'Dream' | 'Want' | 'Wish' | 'Need'] * 100;
    const isRecomendation = currentGoalProbability >= goalPriorityProbability;
    const probableGoalAmount = isRetirement
        ? goalAmtRetirement(infusionArray)
        : goalcalculatorJson[0]?.response?.body?.goalAmt;
    const goalAmount = isRetirement ? goalsData['targetedRetirementIncome'] : goalsData['goalAmount'];
    // const originalAmount = goalDataRecomendation[0].orginalAmount;
    // const isRecomendationClicked =
    //     goalDataRecomendation[0].oneTimeTopUp != 0 ||
    //     goalDataRecomendation[0].topUpAccumulation != 0 ||
    //     goalDataRecomendation[0].toUpDeccumulation != 0 ||
    //     goalDataRecomendation[0].recomendedTenure != 0;

    // const isRecomendation = isRetirement ? (analysisReport['oneTimeTopUp'] == 0 && analysisReport['yearlyTopUpAccumulation']==0 && recomendedTenure==0 && analysisReport['yearlyTopUpDecumulation']<=0) : (analysisReport['oneTimeTopUp'] == 0 &&
    // analysisReport['yearlyTopUpAccumulation']==0 && recomendedTenure==0); // To Do on decumulation negative value // for future use
    const wealthPath = adviceGoals.response.find((item: any) => {
        return item?.goalId && item.goalId === goalId;
    }).response?.body?.pathReport?.wealthPath;
    const getYears = () => {
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
        const years = [startYear];
        for (let i = startYear; i < endYear; i++) {
            years.push(i + 1);
        }
        return years;
    };

    function extractYearRetirement(data: string, value: number): number | null {
        // Split the data string into individual parts
        const parts = data.split(';').map((part) => part.trim());

        const adjustments: { [key: number]: number } = {};

        console.log('data string', data);
        // Process each part to extract years and percentages
        parts.forEach((part) => {
            const years = parseInt(part.split('by')[1].split('years')[0].trim());
            const percentage = parseInt(part.split(':')[1].replace('%', '').trim());
            adjustments[years] = percentage;
        });

        // Iterate through the adjustments to find the first percentage greater than the value
        for (const years of Object.keys(adjustments).map(Number)) {
            if (adjustments[years] >= value) {
                return years; // Return the first years found with percentage greater than value
            }
        }

        return 0; // Return null if no suitable adjustment is found
    }

    // will use this later
    function extractYearForProbability(probData: string): number | null {
        if (probData === 'NA') {
            return 0; // or any other indication of invalid input
        }

        let resultYear: number | null = 0;
        if (isRetirement) {
            resultYear = extractYearRetirement(probData, goalPriorityProbability);
        } else {
            // Loop through the split data
            const splitedData = probData.split(';');
            for (const dataEntry of splitedData) {
                const match = dataEntry.match(/T\+(\d+) years\s*:\s*(\d+)%/);
                if (match) {
                    const year = parseInt(match[1]);
                    const percentage = parseInt(match[2]);
                    // Check if the percentage is greater than or equal to the target value
                    if (percentage >= goalPriorityProbability) {
                        return year; // Return the first year that meets the condition
                    }
                }
            }
        }
        return resultYear; // Return the year or 0 if not found
    }

    let bankruptcyYear: number | undefined = undefined;

    const chartData = getYears().map((year, index) => {
        if (wealthPath?.default?.[index] === 0 && !bankruptcyYear) {
            bankruptcyYear = year;
        }
        return {
            year,
            target: Array.isArray(wealthPath) ? wealthPath[index] : wealthPath?.default?.[index],
            low: Array.isArray(wealthPath) ? 0 : wealthPath?.pessimistic?.[index],
            high: Array.isArray(wealthPath) ? 0 : wealthPath?.optimistic?.[index],
            bankruptcyHigh: bankruptcyYear ? Math.max(...wealthPath?.optimistic) ?? 0 : 0,
            bankruptcyLow: 0
        };
    });
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [showGoalDrawer, setShowGoalDrawer] = useState(false);

    const yearsInRetirement = calculateYearsBetweenDates(
        isRetirement ? goalsData?.planStartRetirement : '',
        isRetirement ? goalsData?.targetDate : ''
    );
    const handleCancelDelete = () => {
        setDeleteModalState(false);
    };

    const handleConfirmDelete = () => {
        const updatedGoals = adviceGoals.formFields.filter((goal) => goal.goalId !== goalId);
        dispatch(
            syncAdviceGoals({
                ...adviceGoals,
                formFields: updatedGoals
            })
        );
        dispatch(updateCurrentStep(1));
        dispatch(
            updateBanner({
                type: 'attention',
                isGoalSummary: true,
                visible: true
            })
        );
        updateStep(1);
    };

    // TODO:: Keeping this comment until we find a space to put in 👇

    // useEffect(() => {
    //     if (adviceGoals.global.probabilityOfSuccess !== currentGoalProbability) {
    //         dispatch(
    //             syncAdviceGoals({
    //                 ...adviceGoals,
    //                 global: { ...adviceGoals?.global, probabilityOfSuccess: currentGoalProbability }
    //             })
    //         );
    //     }
    // }, [currentGoalProbability, adviceGoals, dispatch]);

    const barPrecentage =
        probableGoalAmount < goalAmount
            ? (probableGoalAmount / goalAmount) * 100
            : (goalAmount / probableGoalAmount) * 100;

    const planSummaryProgressBarValues = useMemo<PlanSummaryProgressBarValue[]>(() => {
        const targetGoalAmountLabel = isRetirement ? t('TARGET_ANNUAL_INCOME') : t('TARGET_GOAL');

        if (goalAmount == probableGoalAmount) {
            return [
                {
                    color: '#416AF4',
                    label: targetGoalAmountLabel,
                    percentage: 100
                }
            ];
        }

        if (probableGoalAmount < goalAmount) {
            return [
                {
                    color: '#416AF4',
                    label: targetGoalAmountLabel,
                    percentage: barPrecentage
                },
                {
                    color: '#E4E5E8',
                    label: 'Shortfall',
                    descText: 'Shortfall below Target',
                    percentage: 100 - barPrecentage
                }
            ];
        }

        return [
            {
                color: '#416AF4',
                label: targetGoalAmountLabel,
                percentage: barPrecentage
            },
            {
                percentage: 100 - barPrecentage,
                color: '#00BFB3',
                label: 'Excess',
                descText: 'Excess over Target'
            }
        ];
    }, [goalAmount, probableGoalAmount, barPrecentage, isRetirement]);

    const handleTabChange = (event: React.SyntheticEvent, value: string) => {
        setTabIndex(value);
        return value;
    };

    const formField = adviceGoals.formFields.find((goal) => goal.goalId === goalId);
    const title = formField?.data?.wealthGoalType;

    const { goalPriority } = goalsData || {};

    const GoalHeaderContent = () => (
        <>
            <HeaderDiv isPDF={isPDF}>
                <GoalType type={type} isPDF={isPDF} />
                <Typography variant="headers.h2" label={`${goalName} |`} aria-label={goalName} ml={1.5} />
                <Typography
                    label={t(goalType?.toUpperCase())}
                    aria-label={goalType}
                    ml={0.4}
                    mr={1.5}
                    variant="headers.h2Medium"
                />
                <StyledChipContainer>
                    <Chip type={goalPriority} />
                </StyledChipContainer>
            </HeaderDiv>
        </>
    );
    const GoalContent = () => {
        const GoalContentContainer = styled('div')(() => ({
            paddingBottom: isPDF ? (currentGoalProbability >= goalPriorityProbability ? '270px' : '250px') : 'inherit'
        }));
        return (
            <GoalContentContainer>
                {isGoalRealistic ? (
                    <>
                        <GoalHeaderContent />
                        <PlanSummaryProgressBar
                            highlightedValues={
                                isRetirement
                                    ? [
                                          {
                                              amount: goalAmount,
                                              label: t('TARGET_ANNUAL_INCOME'),
                                              helperText: t('TARGET_ANNUAL_INCOME_HELPER')
                                              // originalAmount: originalAmount // need to for original
                                          },
                                          {
                                              amount: probableGoalAmount,
                                              label: t('PROJECTED_ANNUAL_INCOME'),
                                              helperText: t('PROJECTED_ANNUAL_INCOME_HELPER')
                                          }
                                      ]
                                    : [
                                          {
                                              amount: goalAmount,
                                              label: t('TARGET_GOAL'),
                                              helperText: t('TARGET_GOAL_HELPER')
                                          },

                                          {
                                              amount: probableGoalAmount,
                                              label: t('PROJECTED_GOAL'),
                                              helperText: t('PROJECTED_GOAL_HELPER')
                                          }
                                      ]
                            }
                            onClickEditGoals={() => setShowGoalDrawer(true)}
                            targetProgress={goalAmount < probableGoalAmount ? barPrecentage : 100}
                            values={planSummaryProgressBarValues}
                            isPDF={isPDF}
                        />
                        <ProbSuccessDiv>
                            <ProbabilityOfSuccess
                                dangerValue={goalUnrealisticValue * 100}
                                goalType={goalsData['goalPriority']}
                                target={goalPriorityProbability}
                                thresholdValue={goalPriorityProbability}
                                value={currentGoalProbability}
                                isPDF={isPDF}
                            />
                            <PlanInfoAndReRunSection
                                annualContributionsAmount={calculatedAmountval.annualContribution}
                                handleReRunButtonClicked={(selectedRecomendation) => {
                                    applyRecommendation(selectedRecomendation);
                                }}
                                initialInvestmentAmount={
                                    goalsData?.isIWSApplied
                                        ? goalsData?.recommendedWealthSplit
                                        : calculatedAmountval.initialInvestment
                                }
                                planReRunOptions={[
                                    {
                                        optionLabel: 'Add a lump sum now',
                                        key: 'oneTimeTopUp',
                                        optionValue: analysisReport['oneTimeTopUp']
                                    },
                                    {
                                        optionLabel: 'Increase annual contributions',
                                        key: 'topUpAccumulation',
                                        optionValue: analysisReport['yearlyTopUpAccumulation']
                                    },
                                    {
                                        optionLabel: 'Decrease annual income goal',
                                        key: 'topUpDecumulation',
                                        optionValue: isRetirement ? analysisReport['yearlyTopUpDecumulation'] : 0
                                    },
                                    {
                                        optionLabel: isRetirement ? 'Delay retirement' : 'Delay goal',
                                        key: 'recommendedTenure',
                                        optionValue: extractYearForProbability(analysisReport['recommendedTenure'])
                                    }
                                ]}
                                yearsToGoal={calculateYearsBetweenDates(
                                    startDate,
                                    isRetirement ? goalsData?.planStartRetirement : goalsData?.targetDate
                                )}
                                yearsInRetirement={calculateYearsBetweenDates(
                                    isRetirement ? goalsData?.planStartRetirement : '',
                                    isRetirement ? goalsData?.targetDate : ''
                                )}
                                isRetirement={isRetirement}
                                isRecomendation={isRecomendation}
                                isPDF={isPDF}
                            />
                        </ProbSuccessDiv>
                    </>
                ) : isPDF ? null : (
                    <UnrealisticGoal onClickEdit={() => setShowGoalDrawer(true)} />
                )}
            </GoalContentContainer>
        );
    };

    return (
        <>
            {probableGoalAmount ? (
                <>
                    {isGoalRealistic ? (
                        isPDF ? (
                            <>
                                <PDFHeader pageName={t('GOAL_DETAILS_TITLE')} />
                                <GoalContent />
                                <PDFFooter
                                    currentPage={4 * allRealisticGoals?.findIndex((g) => g.goalId === goalId) + 1}
                                    totalPages={totalPagesForPDF}
                                />
                            </>
                        ) : (
                            <UniversalCard
                                cardSx={{
                                    marginBottom: '24px'
                                }}
                                header="Goal Details"
                                onClick={() => {}}
                                onClickViewAll={() => {}}
                                actionMenuOptions={[
                                    {
                                        label: 'Delete',
                                        onClick: () => setDeleteModalState(true),
                                        icon: 'delete'
                                    }
                                ]}
                            >
                                <GoalContent />
                                <ConfirmationDialog
                                    onCancellation={handleCancelDelete}
                                    onConfirmation={() => handleConfirmDelete()}
                                    open={deleteModalState}
                                    content={`Are you sure you want to delete this goal? This action can’t be undone.`}
                                    confirmButtonLabel={`${t('DELETE_CONFIRMATION_MODAL_DELETE_TEXT')}`}
                                    cancelButtonLabel={`${t('DELETE_CONFIRMATION_MODAL_CANCEL_TEXT')}`}
                                    width="100%"
                                />
                            </UniversalCard>
                        )
                    ) : (
                        !isPDF && <UnrealisticGoal onClickEdit={() => setShowGoalDrawer(true)} />
                    )}

                    {isGoalRealistic ? (
                        isPDF ? (
                            <>
                                <PDFProjectedWealthPathChart
                                    currentPages={4 * allRealisticGoals?.findIndex((g) => g.goalId === goalId) + 2}
                                    totalPages={totalPagesForPDF}
                                    data={chartData}
                                    targetPercentile={wealthPath?.defaultPercentile}
                                    lowPercentile={wealthPath?.pessimisticPercentile}
                                    highPercentile={wealthPath?.optimisticPercentile}
                                    bankruptcyYear={bankruptcyYear}
                                    goalPriority={goalPriority}
                                    priority={goalPriority}
                                    type={type}
                                    goalName={goalName}
                                    goalType={goalType}
                                    retirementYear={isRetirement && new Date(goalsData?.targetDate).getFullYear()}
                                    plotLine={
                                        isRetirement && {
                                            year: new Date(goalsData?.targetDate).getFullYear(),
                                            type: 'retirement'
                                        }
                                    }
                                />
                                <PDFPortfolioComposition
                                    recommendedPortfolioId={analysisReport['recommendedPortfolioId']}
                                    allMarketPortfolios={allMarketPortfolios}
                                    priority={goalPriority}
                                    type={type}
                                    goalName={goalName}
                                    goalType={goalType}
                                    totalPages={totalPagesForPDF}
                                    currentPage={4 * allRealisticGoals?.findIndex((g) => g.goalId === goalId) + 3}
                                />
                                <PDFAllocationsOverTimeChart
                                    priority={goalPriority}
                                    type={type}
                                    goalName={goalName}
                                    goalType={goalType}
                                    portfolioPath={pathReport?.portfolioPath}
                                    retirementYear={isRetirement && new Date(goalsData?.targetDate).getFullYear()}
                                    currentPage={4 * allRealisticGoals?.findIndex((g) => g.goalId === goalId) + 4}
                                    totalPages={totalPagesForPDF}
                                />
                            </>
                        ) : (
                            <>
                                <UniversalCard>
                                    <Tabs
                                        value={tabIndex}
                                        onChange={handleTabChange}
                                        variant={TabVariant.COMPACT_TAB}
                                        tabPanels={[
                                            {
                                                enabled: true,
                                                hidden: false,
                                                title: t('LABEL_PORTFOLIO_COMPOSITION'),
                                                children: (
                                                    <PortfolioCompositionContainer
                                                        recommendedPortfolioId={
                                                            analysisReport['recommendedPortfolioId']
                                                        }
                                                        allMarketPortfolios={allMarketPortfolios}
                                                    />
                                                )
                                            },
                                            {
                                                enabled: true,
                                                hidden: false,
                                                title: t('LABEL_ALLOCATION_OVER_TIME'),
                                                children: (
                                                    <AllocationsOverTimeTab portfolioPath={pathReport?.portfolioPath} />
                                                )
                                            }
                                        ]}
                                    />
                                </UniversalCard>

                                <StyledDisclaimerContent label={t('PORTFOLIO_COMPOSITION_DISCLAIMER')} />

                                <ProjectedWealthPathChart
                                    data={chartData}
                                    targetPercentile={wealthPath?.defaultPercentile}
                                    lowPercentile={wealthPath?.pessimisticPercentile}
                                    highPercentile={wealthPath?.optimisticPercentile}
                                    bankruptcyYear={bankruptcyYear}
                                    goalPriority={goalPriority}
                                    plotLine={
                                        isRetirement && {
                                            year: new Date(goalsData?.targetDate).getFullYear(),
                                            type: 'retirement'
                                        }
                                    }
                                />
                            </>
                        )
                    ) : null}
                    {isGoalRealistic && !isPDF && (
                        <StyledDisclaimerContent label={t('DISCLAIMER_SUMMARY_PORTFOLIO_1')} />
                    )}
                </>
            ) : (
                <Spinner />
            )}

            {isRetirement ? (
                <RetirementGoals
                    selectedGoal={{ title: goalType, type: goalType }}
                    handleClose={() => setShowGoalDrawer(false)}
                    open={showGoalDrawer}
                    yearsInRetirement={parseInt(yearsInRetirement)}
                    goalData={{ ...goalsData, title }}
                />
            ) : (
                <WealthGoals
                    selectedGoal={{ title: goalType, type: goalType }}
                    handleClose={() => setShowGoalDrawer(false)}
                    open={showGoalDrawer}
                    goalData={{ ...goalsData, title }}
                />
            )}
        </>
    );
};

export default GoalSummary;
