import { Typography } from '../../atoms';
import pdffooterftlogo from '../../../assets/logos/pdffooterftlogo.svg';
import { Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface PDFFooterProps {
    currentPage: number;
    totalPages: number;
    useSingleLineFooter?: boolean;
}

const FooterLine2 = styled('span')(({ useSingleLineFooter }: { useSingleLineFooter: boolean }) => ({
    marginTop: '12px',
    paddingTop: useSingleLineFooter ? '70px' : 'inherit',
    marginBottom: '60px',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
}));

export const PDFFooter = ({ currentPage, totalPages, useSingleLineFooter }: PDFFooterProps) => {
    const { t } = useTranslation();
    return (
        <>
            {!useSingleLineFooter && <Typography label={t('PDF_FOOTER_DISCLAIMER_TEXT')} sx={{ fontSize: 14 }} />}
            <FooterLine2 useSingleLineFooter={useSingleLineFooter || false}>
                <img src={pdffooterftlogo} alt="Franklin Templeton" width={370} height={20} />
                <Typography variant="body.smallDemibold" label={t('PDF_FOOTER_USAGE_TEXT') || ''} />
                <Typography variant="body.smallDemibold" label={`${currentPage} OF ${totalPages}` || ''} />
            </FooterLine2>
        </>
    );
};
