import { useForm } from 'react-hook-form';
import { ConfirmationDialog, DrawerComp } from '../../components/molecules';
import { Grid } from '@mui/material';
import { Typography } from '../../components/atoms';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddIncomeSourcesForm } from './add-income-sources-form';
import { useAppSelector, useAppDispatch } from '../../common/hooks';
import { updateClient, updateClientIncomeSources } from '../../features/client';
import { CLIENT_PROFILE_STAGE4 } from '../../common/constants';
import { v4 as uuidv4 } from 'uuid';
import { numberWithCommas, removeNonNumeric } from '../../utils';
import { MonetarySource, Source } from '../../components/molecules/MonetarySource/MonetarySource';
import incomeSource from '../../assets/icons/income_source.svg';
import { getYYYYMMDDfromDate } from '../../common/helper';
import { incomeSources } from 'common/types';
import dayjs, { Dayjs } from 'dayjs';
import { syncAdviceGoals, updateBanner, updateGoal } from '../../features/client-goals';

interface AddIncomeSourcesDrawerProps {
    openDrawer: boolean;
    setOpenDrawer: any;
    mode?: 'add' | 'edit';
    onEditSuccess?: (deletedAccounts?: string[]) => void;
}

interface AddIncomeSourcesFormInput {
    accountId: string;
    sourceName: string;
    sourceType: string;
    sourceOwner: string;
    amount: string | number;
    frequency: string;
    beginningYear: Dayjs | null;
    endYear: Dayjs | null;
    escalateIncome: number;
}
const WIDTH = '396px';

const AddIncomeSourcesDrawer = (props: AddIncomeSourcesDrawerProps) => {
    const clientData = useAppSelector((state) => state.adviceClient);
    const { openDrawer, setOpenDrawer, mode = 'add', onEditSuccess } = props;
    const [addedSources, setAddedSources] = useState<(incomeSources | AddIncomeSourcesFormInput)[]>(
        clientData?.accounts?.incomeSources || []
    );
    const [deletedAccountIds, setDeletedAccountIds] = useState<string[]>([]);
    const [keyId, setKeyId] = useState(uuidv4);
    const [selectedAccountForEdit, setSelectedAccountForEdit] = useState('');
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [incomeSourceEdited, setIncomeSourceEdited] = useState(false);
    const [isDelete, setIsDelete]=useState(false);
    const [currentMode, setCurrentMode] = useState(mode);
    const keysToRemove = ['loading', 'error'];
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    const isPlanRun = adviceGoals?.global?.isPlanRun;
    const banner = adviceGoals?.global?.banner;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const relationships = clientData.relationship;
    const { clientFirstName, clientLastName } = clientData;
    const sourceOwnerOptions = useMemo(() => {
        const options = [
            {
                label: `${clientFirstName} ${clientLastName}`,
                value: `${clientFirstName} ${clientLastName}`,
                disabled: relationships.length === 0
            }
        ];
        if (relationships.length > 0) {
            relationships.forEach((relationship) => {
                options.push({
                    label: `${relationship.firstName} ${relationship.lastName}`,
                    value: `${relationship.firstName} ${relationship.lastName}`,
                    disabled: false
                });
            });
        }
        if (options.length > 1) {
            options.push({ label: 'Joint', value: 'Joint', disabled: false });
        }
        return options;
    }, [relationships, clientFirstName, clientLastName]);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors, isValid },
        trigger,
        getValues,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm<AddIncomeSourcesFormInput>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            sourceOwner: relationships.length === 0 ? sourceOwnerOptions[0].value : '',
            beginningYear: null,
            endYear: null,
            sourceName: '',
            sourceType: '',
            escalateIncome: 0,
            amount: '',
            frequency: undefined
        }
    });
    const frequency = watch('frequency');
    useEffect(() => {
        if (frequency === 'Annually') {
            const startDate = getValues('beginningYear');
            const endDate = getValues('endYear');
            if (startDate) {
                const year = startDate.get('year');
                setValue('beginningYear', dayjs().year(year).month(0).date(1));
            }
            if (endDate) {
                const year = endDate.get('year');
                setValue('endYear', dayjs().year(year).month(0).date(1));
            }
        }
    }, [frequency]);

    useEffect(() => {
        if (selectedAccountForEdit) {
            const incomeSourceData = addedSources.find((el) => el.accountId === selectedAccountForEdit);
            reset({
                ...incomeSourceData,
                beginningYear: dayjs(incomeSourceData?.beginningYear, 'DD-MM-YYYY'),
                endYear: dayjs(incomeSourceData?.endYear, 'DD-MM-YYYY')
            });
        }
    }, [selectedAccountForEdit]);

    const initialFormValues = {
        sourceOwner: relationships.length === 0 ? sourceOwnerOptions[0].value : '',
        beginningYear: null,
        endYear: null,
        sourceName: '',
        sourceType: '',
        escalateIncome: 0,
        amount: '',
        frequency: undefined
    };
    const handleReset = () => {
        reset(initialFormValues);
    };

    const noUserValuesEntered = () => {
        let data = getValues();
        const userValues = Object.values(data);
        const defaultValues = Object.values(initialFormValues);
        // check if there is difference between user entered values and default values
        return JSON.stringify(userValues) === JSON.stringify(defaultValues);
    };

    const onSubmit = (data: AddIncomeSourcesFormInput) => {
        if (isValid) {
            var isaccountName = addedSources
                .filter((source) =>
                    currentMode === 'edit' && selectedAccountForEdit ? source.accountId !== selectedAccountForEdit : true
                )
                .some((i) => i.sourceName.includes(data.sourceName));
            if (isaccountName) {
                setError('sourceName', { type: 'required', message: t('TEXT_ACCOUNT_EXIST') });
                return;
            } else {
                clearErrors('sourceName');
            }
            let currentAddedSources = [...addedSources];
            if (!selectedAccountForEdit) data['accountId'] = uuidv4();
            let amountVal = data['amount'];
            var stringAmountVal = Number(removeNonNumeric(amountVal));
            data['amount'] = stringAmountVal;
            data['beginningYear'] = getYYYYMMDDfromDate(data['beginningYear']);
            data['endYear'] = getYYYYMMDDfromDate(data['endYear']);
            if (currentMode === 'edit' && selectedAccountForEdit) {
                currentAddedSources = currentAddedSources.map((source) =>
                    source.accountId === selectedAccountForEdit ? data : source
                );
            } else {
                currentAddedSources.push(data);
            }
            let incomeSrc = currentAddedSources;
            setAddedSources(currentAddedSources);
            handleReset();
            const copyIncomeSources = Object.assign(clientData.accounts.incomeSources);
            if (currentMode === 'edit' && (!selectedAccountForEdit || !noUserValuesEntered() || !(JSON.stringify(copyIncomeSources) === JSON.stringify(incomeSrc)))) setIncomeSourceEdited(true);
            setSelectedAccountForEdit('');
            setKeyId(uuidv4());
            setCurrentMode('add');
        }
    };

    const onComplete = async () => {
        try {
            if (noUserValuesEntered()) {
                // we just deleted an income source
                checkUnsavedAndhandleClose(false);
            } else {
                await trigger();
                let data = getValues();
                if (!selectedAccountForEdit) data['accountId'] = uuidv4();
                let amountVal = data['amount'];
                var stringAmountVal = Number(removeNonNumeric(amountVal));
                data['amount'] = stringAmountVal;
                data['beginningYear'] = getYYYYMMDDfromDate(data['beginningYear']);
                data['endYear'] = getYYYYMMDDfromDate(data['endYear']);
                if (isValid) {
                    var isaccountName = addedSources
                        .filter((source) =>
                            currentMode === 'edit' && selectedAccountForEdit
                                ? source.accountId !== selectedAccountForEdit
                                : true
                        )
                        .some((i) => i.sourceName.includes(data.sourceName));
                    if (isaccountName) {
                        setError('sourceName', { type: 'required', message: t('TEXT_ACCOUNT_EXIST') });
                        return;
                    } else {
                        clearErrors('sourceName');
                    }
                    const originalData = JSON.parse(JSON.stringify(clientData));
                    const updatedData = Object.assign({}, originalData);
                    keysToRemove.forEach((key) => delete updatedData[key]);
                    if (currentMode === 'edit' && selectedAccountForEdit) {
                        updatedData['accounts'] = {
                            ...updatedData['accounts'],
                            incomeSources: addedSources?.map((source: any) =>
                                source.accountId === selectedAccountForEdit ? data : source
                            )
                        };
                    } else {
                        updatedData['accounts'] = {
                            ...updatedData['accounts'],
                            incomeSources: [...addedSources, data]
                        };
                    }
                    updatedData.stages = [...updatedData.stages, CLIENT_PROFILE_STAGE4];
                    await dispatch(updateClient(updatedData));
                    await dispatch(updateClientIncomeSources(updatedData?.accounts?.incomeSources));
                    let originalGoalsData: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
                    const updatedGoalsData = Object.assign({}, originalGoalsData);
                    // to update the accounts in form fields
                    if (currentMode === 'edit' && onEditSuccess && (!noUserValuesEntered() || incomeSourceEdited))
                        await onEditSuccess();

                    if (updatedGoalsData.global?.hasRunPipe) {
                        updatedGoalsData.global.status = 'editing';
                    }

                    if (updatedGoalsData.formFields?.length > 0) {
                        updatedGoalsData.global.banner = { visible: true, type: 'attention', isGoalSummary: clientData.step === 2 ? false : true }
                        if (currentMode === 'edit' && (!noUserValuesEntered() || incomeSourceEdited)) {
                            updatedGoalsData.formFields.forEach((goal) => {
                                goal.data.sources.incomeSource.forEach((goalIncomeSrc: any) => {
                                    updatedData.accounts.incomeSources.forEach((incomesrc: any) => {
                                        if (incomesrc.accountId === goalIncomeSrc.accountNumber) {
                                            goalIncomeSrc.accountBalance = incomesrc.frequency === "Monthly" ? incomesrc.amount * 12 : incomesrc.amount;
                                            goalIncomeSrc.accountName = incomesrc.sourceName;
                                            goalIncomeSrc.frequency = incomesrc.frequency;
                                            goalIncomeSrc.beginningYear = incomesrc.beginningYear;
                                            goalIncomeSrc.endYear = incomesrc.endYear;
                                        }
                                    });
                                });
                            });
                        }
                        await dispatch(updateGoal(updatedGoalsData));
                    }

                    handleReset();
                    setSelectedAccountForEdit('');
                    setOpenDrawer(false);
                    setAddedSources([]);
                }
            }
        } catch (error) {
            console.error('Error in submitting funds', error);
        }
    };

    const persistUnsavedIncomeSources = async () => {
        try {
            const originalData = JSON.parse(JSON.stringify(clientData));
            const updatedData = Object.assign({}, originalData);
            keysToRemove.forEach((key) => delete updatedData[key]);
            updatedData['accounts'] = {
                ...updatedData['accounts'],
                incomeSources: addedSources.filter((source: any) => !deletedAccountIds.includes(source.accountId))
            };
            // check stages
            // if (updatedData.accounts?.investmentsSources?.length > 0) {
            updatedData.stages = [...updatedData.stages, CLIENT_PROFILE_STAGE4];
            // }

            await dispatch(updateClient(updatedData));
            await dispatch(updateClientIncomeSources(updatedData?.accounts?.incomeSources));
            if (isPlanRun && onEditSuccess && incomeSourceEdited) onEditSuccess(deletedAccountIds);
            if (isPlanRun && incomeSourceEdited) {
                dispatch(
                    updateBanner({ ...banner, type: 'attention', isGoalSummary: clientData.step === 2 ? false : true })
                );
            }
            let originalGoalsData: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
            const updatedGoalsData = Object.assign({}, originalGoalsData);
            if (deletedAccountIds.length > 0) {
                // remove the deleted account from any client goals associated with it
                updatedGoalsData.formFields.forEach((goal) => {
                    goal.data.incomeSources = goal.data?.incomeSources?.filter(
                        (source: any) => !deletedAccountIds.includes(source.accountId)
                    );
                });
                dispatch(syncAdviceGoals(updatedGoalsData));
            }

            if (isPlanRun && incomeSourceEdited) {
                updatedGoalsData.global.banner = { visible: true, type: 'attention', isGoalSummary: clientData.step === 2 ? false : true }
                updatedGoalsData.formFields.forEach((goal) => {
                    goal.data.sources.incomeSource.forEach((goalIncomeSrc: any) => {
                        updatedData.accounts.incomeSources.forEach((incomesrc: any) => {
                            if (incomesrc.accountId === goalIncomeSrc.accountNumber) {
                                goalIncomeSrc.accountBalance = incomesrc.frequency === "Monthly" ? incomesrc.amount * 12 : incomesrc.amount;
                                goalIncomeSrc.accountName = incomesrc.sourceName;
                                goalIncomeSrc.frequency = incomesrc.frequency;
                                goalIncomeSrc.beginningYear = incomesrc.beginningYear;
                                goalIncomeSrc.endYear = incomesrc.endYear;
                            }
                        });
                    });
                });
            }
            if (updatedGoalsData.global.hasRunPipe) {
                updatedGoalsData.global.status = 'editing';
                await dispatch(updateGoal(updatedGoalsData));
            }

            handleReset();
            setOpenDrawer(false);
            setAddedSources([]);
        } catch (error) {
            console.error('Error in submitting funds', error);
        }
    };

    const checkUnsavedAndhandleClose = async (flag: boolean) => {
        if (addedSources?.length > 0 || currentMode === 'edit') await persistUnsavedIncomeSources();
        handleReset();
        setOpenDrawer(flag);
        setAddedSources([]);
    };

    const convertToCommaFormat = (e: any, name: any) => {
        const value = Number(removeNonNumeric(e.target.value));
        setValue(name, `${'$' + numberWithCommas(value)}`);
    };

    const handleEditClick = (accountId: string) => {
        setSelectedAccountForEdit(accountId);
        setCurrentMode('edit');
    };

    const handleDeleteClick = (accountId: string) => {
        setSelectedAccountForEdit(accountId);
        setDeleteModalState(true);
    };

    const handleConfirmDelete = () => {
        handleReset();
        const updatedSources = addedSources.filter((source) => source.accountId !== selectedAccountForEdit);
        setDeletedAccountIds([...deletedAccountIds, selectedAccountForEdit]);
        setAddedSources(updatedSources);
        setDeleteModalState(!deleteModalState);
        setIncomeSourceEdited(true);
        setIsDelete(true);
    };

    const handleCancelDelete = async () => {
        setDeleteModalState(!deleteModalState);
    };

    const isDeleteClicked=()=>{
        if(isDelete){
            return !isDelete
        }
        else{
            return !isValid
        }
    }

    return (
        <DrawerComp
            header={t(currentMode === 'edit' ? 'DRAWER_INCOME_SOURCE_HEADER' : 'DRAWER_ADD_INCOME_SOURCE_HEADER')}
            open={openDrawer}
            handleClose={checkUnsavedAndhandleClose}
            // loading={clientData.loading} // will remove later
            onSubmit={onComplete}
            drawerIcon={<img src={incomeSource} />}
            disableSubmit={isDeleteClicked()}
            secondaryButtonLabel={t(currentMode === 'edit' ? 'SAVE_SOURCE' : 'ADD_ANOTHER_INCOME_SOURCE')}
            onSecondarySubmit={handleSubmit(onSubmit)}
        >
            {addedSources?.length > 0 && (
                <MonetarySource
                    icon={<img src={incomeSource} />}
                    title={t('SAVED_INCOME_SOURCES')}
                    readOnly
                    accountData={addedSources.reduce((acc: Record<string, Source>, curr) => {
                        acc[curr.accountId] = {
                            accountNumber: curr.accountId,
                            accountName: curr.sourceName,
                            isSelected: false,
                            accountBalance: curr.frequency === 'Annually' ? Number(curr.amount) : Number(curr.amount) * 12
                        };
                        return acc;
                    }, {})}
                    onEditClick={handleEditClick}
                    onDeleteClick={handleDeleteClick}
                    isOpen={currentMode === 'edit'}
                />
            )}
            <ConfirmationDialog
                onCancellation={handleCancelDelete}
                onConfirmation={() => handleConfirmDelete()}
                open={deleteModalState}
                content={`${t('DELETE_CONFIRMATION_MODAL_CONTENT')}`}
                confirmButtonLabel={`${t('DELETE_CONFIRMATION_MODAL_DELETE_TEXT')}`}
                cancelButtonLabel={`${t('DELETE_CONFIRMATION_MODAL_CANCEL_TEXT')}`}
                title={t('DELETE_CONFIRMATION_MODAL_HEADER_TEXT')}
                width={WIDTH}
            />

            <Grid container marginTop={2} marginBottom={2}>
                <Typography
                    variant="headers.h4"
                    label={t(
                        currentMode === 'edit' && selectedAccountForEdit
                            ? 'DRAWER_EDIT_INCOME_SOURCE'
                            : 'DRAWER_ADD_NEW_INCOME_SOURCE'
                    )}
                />
            </Grid>

            <AddIncomeSourcesForm
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                errors={errors}
                control={control}
                sourceOwnerOptions={sourceOwnerOptions}
                keyId={keyId}
                convertToCommaFormat={convertToCommaFormat}
                mode={currentMode}
                getValues={getValues}
            />
        </DrawerComp>
    );
};

export default AddIncomeSourcesDrawer;
