import { styled } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RETIREMENT_CATEGORY_GOALS, WEALTH_CATEGORY_GOALS } from '../../common/constants';
import {
    convertDateFormat,
    convertDateFormatReverse,
    getHeaderIconFromType,
    getMMYYYYfromTimeStamp,
    groupGoalsByPriority
} from '../../common/helper';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { GoalTypeProps } from '../../common/types/clients-entity';
import { ComboSelect, Typography } from '../../components/atoms';
import { NoGoal } from '../../components/molecules';
import { GoalSummaryChip } from '../../components/molecules/GoalSummaryChip/GoalSummaryChip';
import { GoalType } from '../../components/molecules/GoalType/GoalType';
import { WealthGoals } from '../../containers/wealth-goals-drawer';
import { RetirementGoals } from '../../containers/retirement-goals-drawer';
import { syncAdviceGoals } from '../../features/client-goals';
import { calculateYearsBetweenDates } from '../../utils/index';

const GoalsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column'
}));
const PageContainer = styled('div')(() => ({
    width: '100%',
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px'
}));
const DisplayRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row'
}));
const GoalsChipsSection = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gap: theme.spacing(2),
    marginTop: theme.spacing(5)
}));
const FilterContainer = styled('div')(() => ({
    maxWidth: '20%',
    marginLeft: 'auto'
}));

const GoalTypeContainer = styled('div')(() => ({
    display: 'flex',
    gap: '12px',
    alignItems: 'center'
}));

export const CreateGoalsStep = () => {
    const { t } = useTranslation();
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    const { formFields } = adviceGoals;
    const [displayedFormFields, setDisplayedFormFields] = useState(formFields || []);
    const dispatch = useAppDispatch();
    const [showSelectedGoalTypeDrawer, setShowSelectedGoalTypeDrawer] = useState<GoalTypeProps | undefined>();
    const [showRetirementGoalDrawer, setShowRetirementGoalDrawer] = useState<GoalTypeProps | undefined>();
    const focusedType = useMemo(() => showSelectedGoalTypeDrawer?.type, [showSelectedGoalTypeDrawer]);
    const [currentGoalData, setCurrentGoalData] = useState<any>();
    const [yearsInRetirement, setYearsInRetirement] = useState('0');
    const sortFormFieldsByPriority = () => {
        const sorted = groupGoalsByPriority([...formFields], ({ data }: { data: any }) => data.goalPriority);
        setDisplayedFormFields(sorted);
    };
    const handleFilterChange = (value: string) => {
        switch (value) {
            case 'Priority':
                sortFormFieldsByPriority();
                break;
            case 'Last Modified':
                setDisplayedFormFields(formFields);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        window.history.replaceState(null, '', '/creategoals');
    }, []);

    const goalDeleted = (goalId: string) => {
        const newFormFields = displayedFormFields.filter((goal) => goal.data.goalId !== goalId);
        dispatch(
            syncAdviceGoals({
                ...adviceGoals,
                formFields: newFormFields,
                global: {
                    ...adviceGoals.global,
                    banner: {
                        visible: true,
                        type: 'attention',
                        isGoalSummary: true,
                        message: t('GOAL_DELETE_BANNER_ATTENTION_MESSAGE_SUMMARY')
                    }
                }
            })
        );
    };

    return (
        <>
            <PageContainer>
                <DisplayRow sx={{ gap: '40px', marginBottom: '16px' }}>
                    <GoalsContainer>
                        <Typography
                            label={t('ADD_RETIREMENT_GOALS')}
                            variant="labels.largeDemibold"
                            marginBottom="16px"
                            color="neutral.black"
                        />
                        <GoalTypeContainer>
                            {RETIREMENT_CATEGORY_GOALS.map((g: GoalTypeProps, key) => {
                                // Change retirement goals props at src/common/constants.ts
                                return (
                                    <GoalType
                                        type={g.type}
                                        key={key}
                                        size="large"
                                        onClickHandler={() => setShowRetirementGoalDrawer(g)}
                                        // uncomment for AEX-1231
                                        // isFocused={focusedType}
                                        // adviceGoals={adviceGoals}
                                    />
                                );
                            })}
                        </GoalTypeContainer>
                    </GoalsContainer>{' '}
                    <GoalsContainer>
                        <Typography
                            label={t('ADD_WEALTH_GOALS')}
                            variant="labels.largeDemibold"
                            marginBottom="18px"
                            color="neutral.black"
                        />

                        <GoalTypeContainer>
                            {WEALTH_CATEGORY_GOALS.map((g: GoalTypeProps, key) => {
                                // Change wealth goals props at src/common/constants.ts
                                return (
                                    <GoalType
                                        type={g.type}
                                        key={key}
                                        size="large"
                                        onClickHandler={() => setShowSelectedGoalTypeDrawer(g)}
                                        isFocused={focusedType}
                                        adviceGoals={adviceGoals}
                                    />
                                );
                            })}
                        </GoalTypeContainer>
                    </GoalsContainer>
                </DisplayRow>
                <FilterContainer>
                    {displayedFormFields?.length > 0 && (
                        <ComboSelect
                            preText="Sort By:"
                            options={[
                                { label: 'Last Modified', value: 'Last Modified' },
                                { label: 'Priority', value: 'Priority' }
                            ]}
                            selectedValue="Last Modified"
                            onSelectChange={handleFilterChange}
                        />
                    )}
                </FilterContainer>
                {displayedFormFields?.length > 0 ? (
                    <GoalsChipsSection>
                        {displayedFormFields.map((goals: any) => {
                            const isRetirement = goals?.data?.wealthGoalType?.toLowerCase?.() === 'retirement';

                            const targetDate = isRetirement
                                ? goals.data.planStartRetirement
                                : goals?.data?.goalDate || goals?.data?.targetDate;

                            return (
                                <GoalSummaryChip
                                    key={goals.goalId}
                                    headerIcon={
                                        <GoalType
                                            type={getHeaderIconFromType(goals?.data?.wealthGoalType) || 'buyahouse'}
                                        />
                                    }
                                    goalType={goals?.data?.wealthGoalType}
                                    goalId={goals?.data?.goalId}
                                    onGoalDelete={goalDeleted}
                                    onEdit={() => {
                                        let data = {};
                                        if (goals.data.goalType === 'WEALTH_GOAL') {
                                            data = {
                                                goalAmount:
                                                    goals?.data?.goalAmount || goals?.data?.targetedRetirementIncome,
                                                goalId: goals.data?.goalId,
                                                goalPriority: goals.data?.goalPriority,
                                                goalName: goals.data?.goalName,
                                                targetDate: goals?.data?.goalDate || goals?.data?.targetDate,
                                                title: goals?.data?.wealthGoalType
                                            };
                                        } else {
                                            const yearsInRetirement = calculateYearsBetweenDates(
                                                goals?.data?.planStartRetirement,
                                                goals?.data?.targetDate
                                            );
                                            setYearsInRetirement(yearsInRetirement);
                                            data = {
                                                goalAmount: goals?.data?.goalAmount,
                                                goalId: goals?.data?.goalId,
                                                goalPriority: goals?.data?.goalPriority,
                                                targetedRetirementIncome: goals?.data?.targetedRetirementIncome,
                                                goalName: goals?.data?.goalName,
                                                yearsInRetirement: yearsInRetirement,
                                                planStartRetirement: goals?.data?.planStartRetirement,
                                                frequency: 'Annually',
                                                title: goals?.data?.wealthGoalType
                                            };
                                        }
                                        setCurrentGoalData(data);
                                        goals.data.goalType === 'WEALTH_GOAL'
                                            ? setShowSelectedGoalTypeDrawer(goals.data)
                                            : setShowRetirementGoalDrawer(goals.data);
                                    }}
                                    goal_name={goals?.data?.goalName}
                                    priority={goals?.data?.goalPriority}
                                    goalAmount={goals?.data?.goalAmount || goals?.data?.targetedRetirementIncome}
                                    target_date_data={
                                        getMMYYYYfromTimeStamp(convertDateFormatReverse(targetDate)) === '-'
                                            ? getMMYYYYfromTimeStamp(convertDateFormat(targetDate))
                                            : getMMYYYYfromTimeStamp(convertDateFormatReverse(targetDate))
                                    }
                                    isRetirement={isRetirement}
                                />
                            );
                        })}
                    </GoalsChipsSection>
                ) : (
                    <NoGoal title={t('NO_GOALS')} infoText={t('NO_GOALS_INFOTEXT')} />
                )}
            </PageContainer>
            {currentGoalData ? (
                <>
                    <RetirementGoals
                        selectedGoal={showRetirementGoalDrawer}
                        handleClose={() => {
                            setCurrentGoalData(undefined);
                            setShowRetirementGoalDrawer(undefined);
                        }}
                        yearsInRetirement={parseInt(yearsInRetirement)}
                        open={Boolean(showRetirementGoalDrawer)}
                        goalData={currentGoalData}
                    />
                    <WealthGoals
                        selectedGoal={showSelectedGoalTypeDrawer}
                        handleClose={() => {
                            setCurrentGoalData(undefined);
                            setShowSelectedGoalTypeDrawer(undefined);
                        }}
                        open={Boolean(showSelectedGoalTypeDrawer)}
                        goalData={currentGoalData}
                    />
                </>
            ) : (
                <>
                    <WealthGoals
                        selectedGoal={showSelectedGoalTypeDrawer}
                        handleClose={() => setShowSelectedGoalTypeDrawer(undefined)}
                        open={Boolean(showSelectedGoalTypeDrawer)}
                    />
                    <RetirementGoals
                        selectedGoal={showRetirementGoalDrawer}
                        handleClose={() => setShowRetirementGoalDrawer(undefined)}
                        open={Boolean(showRetirementGoalDrawer)}
                    />
                </>
            )}
        </>
    );
};
