import { Typography } from '../../../components/atoms';
import { PDFFooter } from '../PDFFooter/PDFFooter';
import { PDFHeader } from '../PDFHeader/PDFHeader';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { PATPalette } from '../../../themes/palette';

export interface PDFImportantInformationProps {
    advisorName: string;
    advisorEmail: string;
    totalPages: number;
}

const Address = styled('span')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    borderTop: `1px solid ${PATPalette.secondary.grey[200]}`
}));

const ADInfo = styled('div')(() => ({
    margin: '20px 0 8px'
}));

const Caveat = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${PATPalette.semantic.text.primary}`,
    marginBottom: '50px',
    width: 'fit-content'
}));
const Column = styled('div')(() => ({
    borderRight: `1px solid ${PATPalette.semantic.text.primary}`,
    padding: '4px 16px'
}));

export const PDFImportantInformation = ({ advisorEmail, advisorName, totalPages }: PDFImportantInformationProps) => {
    const { t } = useTranslation();

    return (
        <div>
            <PDFHeader pageName={t('IMPORTANT_INFO')} />

            <Address>
                <ADInfo>
                    <Typography
                        variant="headers.h5Bold"
                        label={t('ADVISOR_INFO')}
                        sx={{ lineHeight: '24px', marginTop: '15px' }}
                    />
                </ADInfo>
                <Typography
                    variant="headers.h5Bold"
                    label={advisorName}
                    sx={{ lineHeight: '24px', fontSize: '20px' }}
                />
                <Typography
                    variant="headers.h5"
                    label={t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_3')}
                    sx={{ lineHeight: '24px' }}
                />
                <Typography variant="body.largeBold" label={advisorEmail} sx={{ fontWeight: '600' }} />

                <ADInfo>
                    <Typography variant="headers.h5Bold" label={t('IMPORTANT_INFO')} />
                </ADInfo>
            </Address>

            <Typography t={'IMPORTANT_INFO_CONTENT'} variant="body.large" />

            <Caveat>
                <Column>
                    <Typography variant="body.largeBold" label={t('NOT_FDIC')} />
                </Column>
                <Column>
                    <Typography variant="body.largeBold" label={t('LOSE_MONEY')} />
                </Column>
                <Column>
                    <Typography variant="body.largeBold" label={t('NO_BANK')} />
                </Column>
            </Caveat>

            <PDFFooter currentPage={totalPages} totalPages={totalPages} useSingleLineFooter />
        </div>
    );
};
