import { GoalTypeEnum } from '../../../common/types';
import { IColumns, IRows } from '../../atoms/Table/Table';
import { PATPalette } from '../../../themes/palette';
import React from 'react';
import { DateTypography } from '../../molecules/ExhibitTableDateTypography';

export const getExhibitTableValues = ({
    highAndLow,
    targetPercentile,
    probabilityOfSucess,
    plotLine
}: {
    highAndLow: number[][];
    probabilityOfSucess: number[][];
    targetPercentile: string;
    plotLine?:
        | {
              type: GoalTypeEnum;
              year: number;
          }
        | undefined;
}): { exhibitRows: IRows[]; exhibitColumns: IColumns[] } => {
    const exhibitColumns: IColumns[] = [
        { name: 'Date', width: '40%', alignment: 'left' },
        { name: 'Wealth Value Low', alignment: 'right' },
        { name: `Wealth Value Target ${targetPercentile}`, alignment: 'right' },
        { name: 'Wealth Value High', alignment: 'right' }
    ];

    const formatRow = (
        baseValues: number[],
        targetValue: number,
        isRetirement = false
    ): (string | React.ReactNode)[] => [
        <DateTypography key={`date-${baseValues[0]}`} label={baseValues[0]} isRetirement={isRetirement} />,
        formatMoney(baseValues[1] ?? 0),
        formatMoney(targetValue ?? 0),
        formatMoney(baseValues[2] ?? 0)
    ];

    const exhibitRows: IRows[] = [
        {
            id: 'start-year',
            values: formatRow(highAndLow[0], probabilityOfSucess[0][1])
        }
    ];

    if (plotLine) {
        const retirementData = highAndLow.find((hl) => hl[0] === plotLine.year);
        const targetValue = probabilityOfSucess.find((ps) => ps[0] === plotLine.year)?.[1];

        if (retirementData && targetValue) {
            exhibitRows.push({
                id: 'retirement-year',
                values: formatRow(retirementData, targetValue, true)
            });
        }
    }

    const lastIndex = highAndLow.length - 1;
    exhibitRows.push({
        id: 'end-year',
        values: formatRow(highAndLow[lastIndex], probabilityOfSucess[lastIndex][1])
    });

    return { exhibitRows, exhibitColumns };
};

export const labelStyle = {
    color: PATPalette.neutral.black,
    fontFamily: '"TT Commons Pro"',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '0.12px'
};

export const formatMoney = (amount: number) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
    }).format(amount);

export const formatLabelNumber = (value: number) => {
    const round = (n: number, precision: number) => {
        var prec = 10 ** precision;
        return Math.round(n * prec) / prec;
    };

    const abbrev = ['K', 'M', 'B'];

    var base = Math.floor(Math.log(Math.abs(value)) / Math.log(1000));
    var suffix = abbrev[Math.min(abbrev.length - 1, base - 1)];
    base = abbrev.indexOf(suffix) + 1;
    return suffix ? round(value / 1000 ** base, 2) + suffix : '' + value;
};
