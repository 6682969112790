import styled from '@emotion/styled';
import { PlanSummaryProgressBarTarget } from './PlanSummaryProgressBarTarget';
import { PlanSummaryProgressBarLegend } from './PlanSummaryProgressBarLegend';
import _map from 'lodash/map';
import _sum from 'lodash/sum';
import { InfoTooltip } from '../InfoTooltip';

const Container = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column'
}));

const Header = styled('header')<{ hasOriginalAmount: boolean }>(({ hasOriginalAmount = false }) => ({
    display: 'flex',
    gap: '41px',
    marginBottom: hasOriginalAmount ? '12px' : '25px'
}));

const TotalItem = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    '> div': {
        marginBottom: '2px',
        display: 'flex',
        alignItems: 'center'
    },
    p: {
        ...theme.typography.body.large
    },
    strong: {
        ...theme.typography.numbers.largeBold,
        marginBottom: '1px'
    },
    span: {
        ...theme.typography.numbers.small,
        color: '#595959'
    }
}));

const EditLink = styled('button')(({ theme }: any) => ({
    ...theme.typography.body.link.small,
    color: '#416AF4',
    marginBottom: 'auto',
    cursor: 'pointer'
}));

const BarContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '18px',
    marginTop: '10px'
}));

const Bars = styled('div')(() => ({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    width: '100%'
}));

const Bar = styled('div')<{ color: string; percentage: number }>(({ color, percentage }) => ({
    display: 'flex',
    backgroundColor: color,
    width: `${percentage}%`,
    height: '12px',
    borderRadius: '0',
    '&:last-child': {
        borderRadius: '0 100px 100px 0'
    },
    '&:first-child': {
        borderRadius: '100px 0px 0px 100px'
    }
}));

const LegendsContainer = styled('div')(({}) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '33px'
}));

export type PlanSummaryProgressBarValue = {
    label?: string;
    descText?: string;
    color: string;
    percentage: number;
};

type Props = {
    targetProgress: number;
    values: PlanSummaryProgressBarValue[];
    highlightedValues: {
        label: string;
        amount: number;
        originalAmount?: number;
        helperText?: string;
    }[];
    onClickEditGoals(): void;
    isPDF?: boolean;
};

export const PlanSummaryProgressBar = ({
    targetProgress,
    values,
    highlightedValues,
    onClickEditGoals,
    isPDF
}: Props) => {
    const missingProgress = 100 - _sum(_map(values, 'percentage'));

    const hasOriginalAmount = !!highlightedValues?.some(({ originalAmount }) => !!originalAmount);
    return (
        <Container>
            <Header hasOriginalAmount={hasOriginalAmount}>
                {highlightedValues?.map(({ label, amount, originalAmount = 0, helperText = '' }, index) => (
                    <TotalItem key={`highlightedValue_${index}`}>
                        <div>
                            <p>{label}</p>
                            {!isPDF && !!helperText && <InfoTooltip message={helperText} />}
                        </div>

                        <strong>{formatMoney(amount)}</strong>
                        {!!originalAmount && <span>Original: {formatMoney(originalAmount)}</span>}
                    </TotalItem>
                ))}

                {!isPDF && <EditLink onClick={onClickEditGoals}>Edit goal details</EditLink>}
            </Header>

            <BarContainer>
                <Bars>
                    {values.map(({ percentage, color }, index) => (
                        <Bar key={`bar_${index}`} color={color} percentage={percentage} />
                    ))}

                    {missingProgress > 0 && <Bar color="#E4E5E8" percentage={missingProgress} />}
                </Bars>

                <PlanSummaryProgressBarTarget progress={targetProgress} isPDF={isPDF} />
            </BarContainer>

            <LegendsContainer>
                {values
                    .filter(({ label }) => !!label)
                    .map(({ label, color, descText }, index) => (
                        <PlanSummaryProgressBarLegend
                            key={`legend_${index}`}
                            label={label!}
                            color={color}
                            descText={descText}
                        />
                    ))}
            </LegendsContainer>
        </Container>
    );
};

// TODO: Localization
const formatMoney = (amount: number) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
    }).format(amount);
