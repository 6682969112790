import { isEmpty } from 'lodash';
import { RETIREMENT_CATEGORY_GOALS, WEALTH_CATEGORY_GOALS } from './constants';
import {
    AdviceClientType,
    FundingSource,
    GoalPriorityEnum,
    GoalTitleEnum,
    GoalTypeProps,
    ClientStagesType
} from './types';
import dayjs from 'dayjs';

export const isEmailValid = (value: string) =>
    Boolean(value === '' || value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i));

export const isAlphaNumValid = (value: string) => value === '' || value.match(/^[a-zA-Z0-9]+$/);

export const isAlphaNumSpacesValid = (value: string) => value === '' || value.match(/^[a-zA-Z0-9\s]+$/);

export const isAlphaOnlyValid = (value: string) => value === '' || value.match(/^[a-zA-Z]+$/);

export const isAlphaSpacesOnlyValid = (value: string) => value === '' || value.match(/^[a-zA-Z\s]+$/);

// Regex for generic phone number added. TODO get confg from organization
// Allowed patterns
// (123) 456-7890
// 123-456-7890
// 123.456.7890
// 1234567890
// +31636363634
// 075-63546725
// 022-635463933
export const isPhoneNumberValid = (value: string) =>
    // eslint-disable-next-line no-useless-escape
    value === '' || value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);

export const isNumberInRange = (val = 0, min = 0, max = 0) => val >= min && val <= max;

export const hex2rgba = (hex: any, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x: string) => parseInt(x, 16));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getFullMonthFromClientDOB = (dob: string) => {
    return new Date(dob?.split('-')[1]).toLocaleString('default', { month: 'long' });
};

export const getFullYearFromClientDOB = (dob: string) => {
    return dob?.split('-')[2];
};

export const isStageDisabled = (adviceClient: AdviceClientType, stage: string) => {
    // enable all stages after first stage is completed i.e., stages array has > 1 items
    return !adviceClient?.stages?.includes(stage) && !(adviceClient?.stages?.length > 1);
};

export const formatCurrency = (num: any, doNotAllowZero = true) => {
    if (num) {
        let value = num?.toString();
        value = value.replace(/[^.0-9]/g, '');

        if (doNotAllowZero) {
            value = value.replace(/\b0+/g, '');
        }

        let splittedValue = value.split('.');

        if (splittedValue[1]?.length > 2) {
            splittedValue[1] = splittedValue[1][0] + splittedValue[1][1];
            value = [splittedValue[0], splittedValue[1]].join('.');
        }

        if (splittedValue?.length > 2) {
            value = [splittedValue[0], splittedValue[1]].join('.');
        }

        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return value;
    }
    return '';
};

export const getMMYYYYfromTimeStamp = (date: string) => {
    return formatDate(date, 'MM/YYYY');
};

export const getYYYYMMDDfromDate = (value: any, format: string = 'DD-MM-YYYY') => {
    return value.format(format);
};

export const getHeaderIconFromType = (goalTitle?: GoalTitleEnum) => {
    if (goalTitle)
        return [...RETIREMENT_CATEGORY_GOALS, ...WEALTH_CATEGORY_GOALS].find(
            (g: GoalTypeProps) => g.title === goalTitle
        )?.type;
    return WEALTH_CATEGORY_GOALS[0].type;
};

export const groupGoalsByPriority = (values: any, sortCallback: any) => {
    let sorted = values.reduce((a: any, b: any) => {
        const key = typeof sortCallback === 'function' ? sortCallback(b) : b[sortCallback];
        if (!a[key]) {
            a[key] = [b];
        } else {
            a[key] = [...a[key], b];
        }

        return a;
    }, {});
    return Object.values(GoalPriorityEnum)
        .map((gp) => sorted[gp])
        .filter((gp) => gp)
        .flat();
};

export function formatAdvisorName(data: any) {
    return !isEmpty(data) && data?.userType === 'admin' ? data?.adminName || '' : data?.advisorName || '';
}

export const formatDate = (date?: string | Date, format = 'MM/DD/YYYY') => {
    if (!date || !dayjs(date).isValid()) {
        return '-';
    }

    return dayjs(date).format(format);
};

export const convertDateFormat = (dateStr: string) => {
    // Regular expression to check if the date is in dd-mm-yyyy format
    const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4})$/;

    // Check if the dateStr matches the dd-mm-yyyy format
    if (!regex.test(dateStr)) {
        return dateStr;
    }

    // Split the input string by '-'
    const parts = dateStr.split('-');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    // Create the mm-dd-yyyy format
    const convertedDate = `${month}-${day}-${year}`;
    return convertedDate;
};

export const convertDateFormatReverse = (dateStr: string) => {
    // Regular expression to check if the date is in mm-dd-yyyy format
    const regex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(\d{4})$/;

    // Check if the dateStr matches the mm-dd-yyyy format
    if (!regex.test(dateStr)) {
        return dateStr;
    }

    // Split the input string by '-'
    const parts = dateStr.split('-');
    const month = parts[0];
    const day = parts[1];
    const year = parts[2];

    // Create the dd-mm-yyyy format
    const convertedDate = `${day}-${month}-${year}`;
    return convertedDate;
};

export const isStep1Completed = (stages: ClientStagesType[], adviceClient: AdviceClientType) =>
    stages
        .filter((stage) => !stage.optional)
        .every((stage) => {
            const hasStage = adviceClient?.stages?.includes(stage.key);

            const hasAtLeastOneFundingSource = Object.keys(adviceClient.accounts).some(
                (key) => !!adviceClient.accounts?.[key as AccountKeyName]?.length
            );

            return hasStage && hasAtLeastOneFundingSource;
        });
type AccountKeyName = keyof FundingSource;
