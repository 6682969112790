/* eslint-disable operator-linebreak */

import styled from '@emotion/styled';
import { StatusVariant } from './Status';
import { Fragment, useState } from 'react';
import { Row } from './Row';
import { Header } from './Header';
import { SubRow, SubRowProps } from './SubRow';
import PDFViewer from '../../organisms/PDF/PDFViewer';
import GeneratedPlanPDF from '../../organisms/PDF/GeneratedPDFs/GeneratedPlanPDF';

const Table = styled('table')(() => ({
    all: 'unset',
    width: '100%',
    borderCollapse: 'collapse',
    display: 'table',
    minWidth: '1312px'
}));

const TableScroll = styled('div')(() => ({
    overflowX: 'auto'
}));

export type AdminTableItem = {
    id: string;
    status: StatusVariant;
    probabilityOfSuccess?: number;
    clientGoalName: string;
    portfolioApproach?: string;
    riskGoalPriority: string;
    portfolioValue: number;
    numberofGoals: number;
    equityFixedIncome?: string;
    subItems?: SubRowProps[];
    planCreationDate?: string;
};

type Props = {
    items: AdminTableItem[];
};

export const AdminTable = ({ items = [] }: Props) => {
    const [closedRows, setClosedRows] = useState<string[]>([]);
    const [openPDF, setOpenPDF] = useState(false);

    const handleToggleExpanded = (id: string, isExpanded: boolean) => {
        setClosedRows(isExpanded ? [...closedRows, id] : closedRows.filter((item) => item !== id));
    };

    return (
        <>
            {openPDF && (
                <PDFViewer open={openPDF} handleClose={() => setOpenPDF(false)}>
                    <GeneratedPlanPDF />
                </PDFViewer>
            )}
            <TableScroll>
                <Table>
                    <Header />
                    <tbody>
                        {items.map(({ subItems = [], ...item }) => {
                            const { id } = item;
                            const isExpanded = !closedRows.includes(id);

                            return (
                                <Fragment key={id}>
                                    <Row
                                        {...item}
                                        subItems={subItems}
                                        isExpanded={isExpanded}
                                        onToggleExpanded={() => handleToggleExpanded(id, isExpanded)}
                                        printPDF={() => setOpenPDF(true)}
                                    />

                                    {isExpanded && (
                                        <>
                                            {subItems?.map((subItem, index) => {
                                                return <SubRow {...subItem} key={`${id}_${subItem.id}_${index}`} />;
                                            })}
                                        </>
                                    )}
                                </Fragment>
                            );
                        })}
                    </tbody>
                </Table>
            </TableScroll>
        </>
    );
};
