import styled from '@emotion/styled';
import expandMoreIcon from '../../../../assets/icons/expander.svg';
import { AdminTableItem } from '..';
import { Status } from '../Status';
import { MoreActionsMenu } from '../../../molecules/MoreActionsMenu';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { syncAdviceGoals } from '../../../../features/client-goals';
import { syncAdviceClient } from '../../../../features/client';
import { API_VERSION } from '../../../../common/constants';
import { formatCurrency } from '../../../../utils';

export const Tr = styled('tr')<{ isExpanded?: boolean }>(({ isExpanded = false }) => ({
    verticalAlign: 'middle',
    height: '72px',
    ':not(:last-child)': {
        borderBottom: '1px solid #E6E6E6'
    },
    ':hover': {
        background: '#EFF4FF'
    },
    ...(isExpanded && {
        background: '#F8FAFD'
    })
}));

export const Td = styled('td')(() => ({
    verticalAlign: 'middle',
    paddingLeft: '16px'
}));

export const TdCenter = styled('td')(() => ({
    verticalAlign: 'middle',
    paddingLeft: '16px'
}));

const ExpandButton = styled('button')<{ isExpanded?: boolean }>(({ isExpanded }) => ({
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    width: '52px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...(isExpanded && {
        img: {
            transform: 'rotate(90deg)'
        }
    })
}));

const Text = styled('span')(({ theme }) => ({
    ...theme.typography.tables.rows
}));

const ColumnCenter = styled(Td)(() => ({
    paddingLeft: 0,
    textAlign: 'center'
}));

export const ActionButton = styled('button')(() => ({
    background: 'transparent',
    border: 'none',
    margin: 0,
    padding: 0,
    cursor: 'pointer',
    '> svg': { color: '#595959' }
}));

type Props = AdminTableItem & {
    onToggleExpanded?(): void;
    isExpanded?: boolean;
    printPDF(): void;
};

export const Row = ({
    status,
    clientGoalName,
    // portfolioApproach,
    riskGoalPriority,
    portfolioValue,
    numberofGoals,
    probabilityOfSuccess,
    equityFixedIncome,
    onToggleExpanded,
    isExpanded = false,
    subItems,
    id,
    planCreationDate,
    printPDF
}: Props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { advisorConsole } = useAppSelector((state) => ({
        tenant: state?.global?.globalConfig?.subdomain,
        advisorConsole: state?.advisorConsole
    }));

    const doc = advisorConsole.keyValueRequest[id];
    const shouldDisablePlanDetails = !doc?.response || doc?.response?.length === 0;

    // eslint-disable-next-line no-unused-vars
    enum Stage {
        // eslint-disable-next-line no-unused-vars
        createClient = 0,
        // eslint-disable-next-line no-unused-vars
        createGoals = 1,
        // eslint-disable-next-line no-unused-vars
        reviewPlan = 2
    }

    const loadClientToStore = (stage: Stage) => {
        const recommendationArr: any[] = [];
        if (doc.form_fields) {
            doc.form_fields.map((goal: any) => {
                recommendationArr.push({
                    goalId: goal.goalId,
                    orginalAmount: goal.goalAmount,
                    oneTimeTopUp: 0,
                    topUpAccumulation: 0,
                    toUpDeccumulation: 0,
                    recomendedTenure: 0
                });
            });
        }

        const adviceGoals = {
            _id: doc.proposalId || '',
            apiName: doc.api_name || '',
            tenant: doc.tenant,
            apiVer: doc.api_ver || API_VERSION,
            assignedClientId: doc.assignedClientId,
            formFields: doc.form_fields || [],
            request: doc.request || [],
            response: doc.response || [],
            isMultiGoal: doc.is_multi_goal || false,
            iwsApplied: doc.iws_applied || false,
            recommendation: recommendationArr,
            loading: false,
            error: '',
            goeConfigEmail: doc.goeConfigEmail,
            global: doc.global
        };
        dispatch(syncAdviceGoals(adviceGoals));

        dispatch(
            syncAdviceClient({
                _id: doc._id,
                accounts: doc.accounts,
                clientDob: doc.clientDob,
                clientFirstName: doc.clientFirstName,
                clientLastName: doc.clientLastName,
                relationship: doc.relationship,
                riskProfile: doc.riskProfile,
                stages: doc.stages,
                step: stage,
                tenant: doc.tenant,
                loading: false
            })
        );
    };
    const navigateToStage = (stage: Stage) => {
        if (doc) {
            loadClientToStore(stage);
            if (stage === Stage.createClient) navigate('/createclient');
            if (stage === Stage.createGoals) navigate('/creategoals');
            if (stage === Stage.reviewPlan) navigate('/reviewplan');
        }
    };

    const onPrintPDF = () => {
        if (doc) {
            loadClientToStore(Stage.reviewPlan);
            printPDF();
        }
    };

    return (
        <Tr isExpanded={isExpanded}>
            <Td style={{ paddingLeft: 0 }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {!!subItems?.length && onToggleExpanded && (
                        <ExpandButton isExpanded={isExpanded} onClick={onToggleExpanded}>
                            <img src={expandMoreIcon} />
                        </ExpandButton>
                    )}
                </div>
            </Td>
            <Td>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Status variant={status} />
                </div>
            </Td>
            <Td>
                <Text>{clientGoalName}</Text>
            </Td>
            {/* <Td>
                <Text>{portfolioApproach}</Text>
            </Td> */}
            <Td>
                <Text>{riskGoalPriority}</Text>
            </Td>
            <Td
                style={{
                    paddingLeft: 0,
                    paddingRight: 20,
                    textAlign: 'right'
                }}
            >
                <Text
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 4
                    }}
                >
                    {formatMoney(portfolioValue)}{' '}
                </Text>
            </Td>
            <Td
                style={{
                    paddingLeft: 0,
                    paddingRight: 20,
                    textAlign: 'right'
                }}
            >
                <Text>{numberofGoals}</Text>
            </Td>
            <ColumnCenter>
                <Text>
                    {probabilityOfSuccess === undefined ? undefined : `${formatCurrency(probabilityOfSuccess)}%`}
                </Text>
            </ColumnCenter>
            <ColumnCenter>
                <Text>{equityFixedIncome || ''}</Text>
            </ColumnCenter>
            <Td>
                <Text>{planCreationDate}</Text>
            </Td>
            <Td style={{ padding: 0 }}>
                <MoreActionsMenu
                    options={[
                        {
                            label: 'Client Profile',
                            icon: 'client',
                            onClick: () => navigateToStage(Stage.createClient)
                        },
                        {
                            label: 'Goals',
                            icon: 'goal',
                            disabled: doc?.step < 1,
                            onClick: () => navigateToStage(Stage.createGoals)
                        },
                        {
                            label: 'Plan Details',
                            icon: 'plan-details',
                            disabled: shouldDisablePlanDetails,
                            onClick: () => navigateToStage(Stage.reviewPlan)
                        },
                        {
                            label: 'Delete Plan',
                            icon: 'delete',
                            disabled: true,
                            onClick: () => window.alert('Delete Plan')
                        },
                        {
                            label: 'Create PDF',
                            icon: 'download',
                            disabled: shouldDisablePlanDetails,
                            onClick: onPrintPDF
                        }
                    ]}
                />
            </Td>
        </Tr>
    );
};

// TODO: Localization
const formatMoney = (amount: number) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
    }).format(amount);
